import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './Login.css';
import Swal from 'sweetalert2'; 
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import Alert from '../../components/Alert/Alert'; 
import hideicon from './hide.png';
import showicon from './show.png';
const Login = () => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isWinery, setIsWinery] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [retryAfter, setRetryAfter] = useState(0);
  const [isLoading, setIsLoading] = useState(false); // State for loading status
  const timerRef = useRef(null);

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!email) {
      setEmailError('Email is required');
    } else if (!re.test(email)) {
      setEmailError('Please enter a valid email address');
    } else {
      setEmailError('');
    }
  };

  const validatePassword = (password) => {
    if (!password) {
      setPasswordError('Password is required');
    } else if (password.length < 8) {
      setPasswordError('Password must be at least 8 characters');
    } else {
      setPasswordError('');
    }
  };

  useEffect(() => {
    setIsFormValid(email && password && !emailError && !passwordError);
  }, [email, password, emailError, passwordError]);

  useEffect(() => {
    if (retryAfter > 0) {
      timerRef.current = setInterval(() => {
        setRetryAfter((prev) => {
          if (prev <= 1) {
            clearInterval(timerRef.current);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(timerRef.current);
    }
  }, [retryAfter]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isFormValid) {
      setIsLoading(true); // Show loader
      try {
        const apiUrl = isWinery ? 'https://timesavor-server.onrender.com/api/business/login' : 'https://timesavor-server.onrender.com/api/buyer/login';
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, password }),
        });
  
        if (response.status === 429) {
          const retryAfterSeconds = response.headers.get('Retry-After') || 120;
          setRetryAfter(parseInt(retryAfterSeconds, 10));
          Swal.fire({
            title: 'Too Many Requests!',
            text: `Too many requests from this IP, please try again after ${retryAfterSeconds} seconds.`,
            showConfirmButton: false,
          });
          return;
        }
        const data = await response.json();
  
        if (!response.ok) {
          Swal.fire({
            title: 'Sorry',
            text: data.message || 'An error occurred during login. Please try again.',
            showConfirmButton: false,
          });
          return;
        }
  
        Swal.fire('Success!', 'Login successful.', 'success');
        localStorage.setItem('authToken', data.token);
  
        if (isWinery) {
          history.push('/winery', {
            token: data.token,
            email,
            ...data.business
          });
        } else {
          history.push('/profile', {
            token: data.token,
            email,
            ...data.buyer
          });
        }
      } catch (error) {
        Swal.fire('Error!', 'An error occurred during login. Please try again.', 'error');
      } finally {
        setIsLoading(false); // Hide loader
      }
    }
  };
  

  return (
    <>
      <IndexNavbar />
      <div className="login-page">
        <div className="login-card">
          <h1 className="login-title">Welcome Back</h1>
          <div className="toggle-container">
            <button 
              className={`toggle-button ${!isWinery ? 'active' : ''}`}
              onClick={() => setIsWinery(false)}
            >
              User
            </button>
            <button 
              className={`toggle-button ${isWinery ? 'active' : ''}`}
              onClick={() => setIsWinery(true)}
            >
              Winery
            </button>
          </div>
          <form onSubmit={handleSubmit} className="login-form">
            <div className="form-field">
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  validateEmail(e.target.value);
                }}
                onBlur={() => validateEmail(email)}
                className="form-input"
                placeholder=" "
                required
              />
              <label htmlFor="email" className="form-label">Email <span style={{ color: '#800020' , fontSize : "15px" }}>*</span></label>
              {emailError && <span className="error-message">{emailError}</span>}
            </div>
            <div className="form-field">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  validatePassword(e.target.value);
                }}
                onBlur={() => validatePassword(password)}
                className="form-input"
                placeholder=" "
                required
              />
              <label htmlFor="password" className="form-label">Password <span style={{ color: '#800020' , fontSize : "15px" }}>*</span></label>
              <span 
                className="password-toggle" 
                onClick={() => setShowPassword(!showPassword)}
              >
                  <img src={showPassword ? hideicon : showicon} alt="toggle visibility" style={{ width: '19px', height: '19px' }} />
              </span>
              {passwordError && <span className="error-message">{passwordError}</span>}
            </div>
            <div className="remember-me">
              <input
                type="checkbox"
                id="rememberMe"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              />
              <label htmlFor="rememberMe">Remember me</label>
            </div>
            <button type="submit" className={`submit-button ${isLoading ? 'loading' : ''}`} disabled={!isFormValid || retryAfter > 0}>
              {isLoading ? (
                <>
                  Checking credentials
                  <div className="loader">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </>
              ) : (
                `Log In as ${isWinery ? 'Winery' : 'User'}`
              )}
            </button>
            {retryAfter > 0 && (
              <div className="retry-message">
                Please wait {retryAfter} seconds before trying again.
              </div>
            )}
          </form>

          <div className="register-link">
            
           <Link to="/ForgotPassword">Forgot Password?</Link>
          </div>

          <div className="register-link">
          <span>If your email is not verified? <Link to="/otpVerification">verify it now</Link>.</span>
          </div>
          
          <div className="register-link">
            
            Don't have an account? <Link to="/register">Register here</Link>
          </div>
          
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default Login;
