import React, { useEffect } from 'react';
import htmlContent from './privacy.html';
import '../assets/styles/PrivacyPolicy.css';
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
function Privacy() {
  useEffect(() => {
    const sections = document.querySelectorAll('.privacy-section');
    sections.forEach((section, index) => {
      setTimeout(() => {
        section.classList.add('animate');
      }, index * 200); // Adjust delay as needed
    });
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <IndexNavbar/>
    <div  style={{marginTop:"90px",padding:"20px"}} dangerouslySetInnerHTML={{ __html: htmlContent }} />
    <Footer/>
    </>
   
  );
}

export default Privacy;
