import React from "react";
import { Link } from "react-router-dom";
import google from './email.png';
import instagram from './instagram-logo.png';
import appstore from './apps.png';
import facebook from './social.png';
import playstore from './googles.png';

const montserratStyle = {
  fontFamily: "'Montserrat', sans-serif",
};

const linkStyle = {
  color: "#800020",
  textDecoration: "none",
  paddingBottom: "8px",
};

export default function Footer({ scrollToAboutUs, scrollToContact }) {
  return (
    <footer className="relative bg-blueGray-200 pt-1 pb-1" style={montserratStyle}>
      <div className="mx-auto w-full max-w-screen-xl p-4 py-8 lg:py-8">
        <div className="flex flex-wrap text-center lg:text-left justify-between">
          {/* App Store and Google Play Links */}
          <div className="w-full lg:w-3/12 px-4 mb-4 lg:mb-0 md:justify-start">
            <h4 style={{ color: "#800020" }} className="text-lg font-semibold flex justify-center lg:justify-start">An App for Wine Country</h4>
            <div className="mt-2 flex justify-center lg:justify-start" >
              <a href="https://apps.apple.com" target="_blank" rel="noopener noreferrer">
                <img style={{ width: "120px", height: "40px" }} src="https://1000logos.net/wp-content/uploads/2020/08/apple-app-store-logo-1024x354.jpg" alt="App Store" className="inline-block mr-2" />
              </a>
              <a href="https://play.google.com" target="_blank" rel="noopener noreferrer">
                <img style={{ width: "120px", height: "40px" }} src="https://seeklogo.com/images/G/get-it-on-google-play-badge-logo-8CDE582776-seeklogo.com.png" alt="Google Play" className="inline-block" />
              </a>
            </div>
          </div>

          {/* Company Logo, About, and How It Works Links */}
          <div className="w-full lg:w-3/12 px-4 mb-4 lg:mb-0">
            <div className="flex flex-col items-center lg:items-start">
              <h4 style={{ color: "#800020" }} className="text-lg font-semibold">TimeSavor</h4>
              <Link
                to="/About"
                style={{ ...linkStyle, marginBottom: '-10px' }}  // Reduced margin between links
                className="hover:underline"
                onMouseEnter={e => (e.target.style.textDecoration = 'underline')}
                onMouseLeave={e => (e.target.style.textDecoration = 'none')}
              >
                About Us
              </Link>
              <Link
                style={{ ...linkStyle, marginTop: '4px' }}  // Reduced margin between links
                to="/Howitworks"
                className="hover:underline"
                onMouseEnter={e => (e.target.style.textDecoration = 'underline')}
                onMouseLeave={e => (e.target.style.textDecoration = 'none')}
              >
                How It Works
              </Link>
            </div>
          </div>
          {/* Social Media Icons */}
          <div className="w-full lg:w-3/12 px-4 mb-4 lg:mb-0 md:justify-end lg:justify-end">
            <h4 style={{ color: "#800020"}} className="text-lg font-semibold flex justify-center md:justify-end">Connect With Us</h4>
            <div className="mt-2 flex justify-center md:justify-end">
  <a href="https://www.instagram.com/timesavorapp/" target="_blank" rel="noopener noreferrer">
    <img src={instagram} alt="Instagram" className="icon h-8 w-8 mt-1 mr-4" />
  </a>
  <a href="https://www.facebook.com/profile.php?id=61561647449831" target="_blank" rel="noopener noreferrer">
    <img src={facebook} alt="Facebook" className="icon h-10 w-10 mr-4" />
  </a>
  <a href="mailto:contact@timesavorapp.com" target="_blank" rel="noopener noreferrer">
    <img src={google} alt="Google" className="icon h-10 w-10 " style={{color: "#800020"}} />
  </a>
</div>

          </div>
        </div>
        <div className="flex flex-wrap items-center justify-between mt-4">
          <div className="w-full md:w-4/12 text-center md:text-left flex justify-center md:justify-start">
            <div style={{ color: "#800020" }} className="text-sm text-blueGray-500 py-1">
            <span style={{ 

fontFamily: "Baskervville, serif", 
// paddingBottom: "20px",
position: "relative",
 // Adjust this value to move the symbol up slightly
}}>
TimeSavor 
</span>
              
               <span style={{ 

    fontFamily: "Baskervville, serif", 
    // paddingBottom: "20px",
    position: "relative",
    top: "-2px"  // Adjust this value to move the symbol up slightly
  }}>
  ®
  </span> <span style={{ fontFamily: "Baskervville, serif",fontSize:"16px" }}> is a registered trademark of TimeSavor.</span> 
             
            </div>
          </div>
          <div className="w-full md:w-4/12 text-center flex justify-center">
            <div className="text-sm text-blueGray-500 font-semibold py-1">
              <Link to="/privacypolicy" className="hover:text-blueGray-800 ml-1">
                <button style={{ color: "#800020"  ,fontFamily: "Baskervville, serif", fontSize:"15px"}}>Privacy Policy</button>
              </Link>
              <span style={{ color: "#800020", margin: "0 8px" }}>·</span>
              <Link to="/terms" className="hover:text-blueGray-800 ml-1">
                <button style={{ color: "#800020",fontFamily: "Baskervville, serif", fontSize:"15px" }}>Terms</button>
              </Link>
              <span style={{ color: "#800020", margin: "0 8px" }}>·</span>
              <Link to="/cookiepolicy" className="hover:text-blueGray-800 ml-1">
                <button style={{ color: "#800020",fontFamily: "Baskervville, serif", fontSize:"15px" }}>Cookie Policy</button>
              </Link>
            </div>
          </div>
          <div className="w-full md:w-4/12 text-center md:text-right flex justify-center md:justify-end mt-2 md:mt-0">
            <div style={{ color: "#800020", fontFamily: "Baskervville, serif", fontSize:"15px" }} className="text-sm text-blueGray-500 py-1">
              Owned by Varela Ventures, LLC
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
