import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import './ContactForm.css';

const validationSchema = yup.object().shape({
  name: yup.string().required('Name is required').min(2, 'Name must be at least 2 characters'),
  email: yup.string().email('Invalid email').required('Email is required'),
  message: yup.string().required('Message is required').min(10, 'Message must be at least 10 characters'),
});

const ContactForm = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await fetch('https://timesavor-server.onrender.com/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Failed to send message. Please try again.');
      }

      setSuccess('Your message has been sent successfully!');
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-gradient-to-r from-blue-50 to-blue-100 py-20 px-4">
      <div className="container mx-auto">
        <div className="text-center mb-12">
          <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
            <i className="fa fa-envelope fa-1x" aria-hidden="true"></i>
          </div>
          <h3 className="text-3xl mb-2 font-semibold leading-normal" style={{ color: '#800020' }}>
            Get In Touch
          </h3>
        </div>
        <div className="flex flex-col items-center py-12 bg-gray-50 min-h-screen">
          <div className="container-cont">
            <div className="picture-container-cont">
              <div className="picture-cont">
                <img
                  src="https://miro.medium.com/v2/resize:fit:1400/0*OckilgOyByn-x242.gif"
                  alt="Mail Icon"
                />
              </div>
            </div>
            <div className="contact-form-container-cont">
              <form onSubmit={handleSubmit(onSubmit)} className="contact-form-cont">
                <input
                  type="text"
                  name="name"
                  {...register('name')}
                  className="email-input-cont"
                  placeholder="Name"
                />
                {errors.name && (
                  <div className="text-red-500 text-sm">{errors.name.message}</div>
                )}
                <input
                  type="email"
                  name="email"
                  {...register('email')}
                  className="email-input-cont"
                  placeholder="Email"
                />
                {errors.email && (
                  <div className="text-red-500 text-sm">{errors.email.message}</div>
                )}
                <textarea
                  name="message"
                  cols="30"
                  rows="5"
                  {...register('message')}
                  className="message-cont"
                  placeholder="Message..."
                ></textarea>
                {errors.message && (
                  <div className="text-red-500 text-sm">{errors.message.message}</div>
                )}
                {error && (
                  <div className="text-red-500 text-sm text-center mb-4">{error}</div>
                )}
                {success && (
                  <div className="text-green-500 text-sm text-center mb-4">{success}</div>
                )}
                <button
                  type="submit"
                  className={`submit-cont ${loading ? 'bg-gray-400' : 'bg-blueGray-700'} text-white font-bold py-2 px-4 rounded flex items-center justify-center`}
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <span className="dot-trail"></span>
                      <span className="ml-2">Sending...</span>
                    </>
                  ) : (
                    'Submit'
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
