import React, { useState, useEffect, useRef } from "react";
import "./WineryProfile.css";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Swal from "sweetalert2";
import ReactModal from 'react-modal';
import Spinner from "components/Spinner/Spinner";

import {
  GoogleMap,
  useJsApiLoader,
  Autocomplete,
} from "@react-google-maps/api";
import { useHistory } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Footer from "components/Footers/Footer.js";
import BusinessHours from "../components/Hours/BusinessHoursEdit";
import MultiSelect from "../components/MultiSelectEdit";
// import DeleteIcon from '@mui/icons-material/Delete';
import { AiFillDelete } from 'react-icons/ai';
// import {
//   suitabilityOptions,
//   categoryTypesOptions,
//   attractionsOptions,
//   farmingOptions,
//   grapeVarietalsOptions,
// } from "../components/Options/options";
import { Box, IconButton } from "@mui/material";
import axios from "axios";
const GOOGLE_MAPS_API_KEY = "AIzaSyA16A5xGXypdMIWhyQdnmoYtBwPsgXwVTk";

const WineryProfile = () => {
  const history = useHistory();
   const [wineryData, setWineryData] = useState(null);
   const [suitabilityOptions , setSuitabilityOptions] = useState([]);
   const [categoryTypesOptions , setCategoryTypesOptions] = useState([]);
   const [attractionsOptions , setAttractionsOptions] = useState([]);
   const [farmingOptions , setFarmingOptions] = useState([]);
   const [grapeVarietalsOptions , setGrapeVarietalsOptions] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [cityStateData, setCityStateData] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [regions, setRegions] = useState([]);
  const [subRegions, setSubRegions] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [existingImages, setExistingImages] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [newImagePreviews, setNewImagePreviews] = useState([]);
  const [imagesToRemove, setImagesToRemove] = useState([]); // Track images to remove
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');
  const [currentLogo, setCurrentLogo] = useState(null); 
  const [loading, setLoading] = useState(false);
  const [openMultiSelect, setOpenMultiSelect] = useState(null);
  
  const handleMultiSelectToggle = (selectName) => {
    setOpenMultiSelect(prev => (prev === selectName ? null : selectName));
  }
  
  const [formData, setFormData] = useState({
    Heading: "",
    Address: "",
    Direction: "",
    Mob: "",
    Description: "",
    ShopTime: "",
    DeliverWithinMinutes: "",
    ShopWebsiteURL: "",
    CategoryTypes: "",
    FacebookURL:"",
    InstagramURL:"",
TwitterURL :"",
Price: "",
    State: "",
    Region: "",
    SubRegion: "",
    Suitability: [],
    Attractions: [],
    Farming: [],
    GrapeVarietals: [],
    Latitude: "",
    Longitude: "",
    Images: [],
  });

  const fetchWineryData = async () => {
    try {
      const token = localStorage.getItem("authToken"); // Fetch token from localStorage
      const response = await fetch(
        "https://timesavor-server.onrender.com/api/business/profile",
        {
          method: "GET",
          headers: {
            "x-access-token": token, // Use token in request headers
          },
        }
      );
      if (!response.ok && response.status === 404) {
        history.push('/profile');
        return;
      }
      if (!response.ok) {
        throw new Error(
          "Failed to fetch winery profile. Please log in first."
        );
      }

     

      const data = await response.json();
      setWineryData(data.shop);
      setCurrentLogo(data.shop.shopLogo); 
      setExistingImages(data.shop.Images || []);

      // Set form data with existing winery data
      setFormData({
        Heading: data.shop.Heading || "",
        Address: data.shop.Address || "",
        Direction: data.shop.Direction || "",
        Mob: data.shop.Mob || "",
        Description: data.shop.Description || "",
        ShopTime: data.shop.ShopTime || "",
        DeliverWithinMinutes: data.shop.DeliverWithinMinutes || "",
        FacebookURL:data.shop.FacebookURL || "",
        InstagramURL:data.shop.InstagramURL || "",
    TwitterURL :data.shop.TwitterURL || "",
        ShopWebsiteURL: data.shop.ShopWebsiteURL || "",
        CategoryTypes: Array.isArray(data.shop.CategoryTypes)
          ? data.shop.CategoryTypes
          : (data.shop.CategoryTypes || "").split(","),
        Price: data.shop.Price || "",
        State: data.shop.State || "",
        Region: data.shop.Region || "",
        SubRegion: data.shop.SubRegion || "",
        Suitability: Array.isArray(data.shop.Suitability)
          ? data.shop.Suitability
          : (data.shop.Suitability || "").split(","),
        Attractions: Array.isArray(data.shop.Attractions)
          ? data.shop.Attractions
          : (data.shop.Attractions || "").split(","),
        Farming: Array.isArray(data.shop.Farming)
          ? data.shop.Farming
          : (data.shop.Farming || "").split(","),
        GrapeVarietals: Array.isArray(data.shop.GrapeVarietals)
          ? data.shop.GrapeVarietals
          : (data.shop.GrapeVarietals || "").split(","),
        Latitude: data.shop.Location?.coordinates[1] || "",
        Longitude: data.shop.Location?.coordinates[0] || "",
        Images: data.shop.Images || [],
      });
    } catch (error) {
     
      Swal.fire("Error!", error.message, "error");
    }
  };

  const fetchCategoryOptions = async () =>{
    try {
      const response = await fetch(
        "https://timesavor-server.onrender.com/api/buyer/getAllCatOptions"
      );
      if (!response.ok) {
        throw new Error("Failed to fetch city state data.");
      }
      const data = await response.json();
      setSuitabilityOptions(data.Suitability || []);
      setCategoryTypesOptions(data.Experience || []);
      setAttractionsOptions(data.Attractions || []);
      setFarmingOptions(data.Farming || []);
      setGrapeVarietalsOptions(data.GrapeVarietals || []);
    } catch (error) {
      Swal.fire("Error!", error.message, "error");
    }
  }

  useEffect(() => {
    
    fetchCategoryOptions();
    fetchWineryData();
  }, []);

  useEffect(() => {
    const fetchCityStateData = async () => {
      try {
        const response = await fetch(
          "https://timesavor-server.onrender.com/api/business/getCityState"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch city state data.");
        }
        const data = await response.json();
        setCityStateData(data.cityStates);
      } catch (error) {
        Swal.fire("Error!", error.message, "error");
      }
    };

    fetchCityStateData();
    fetchCategoryOptions();

  }, []);
  const handleStateChange = (e) => {
    const selectedState = e.target.value;
    setSelectedState(selectedState);
  
    const stateData = cityStateData.find(
      (state) => state.state === selectedState
    );
  
    // Sort regions alphabetically
    const sortedRegions = stateData ? stateData.regions.sort((a, b) => a.name.localeCompare(b.name)) : [];
  
    setRegions(sortedRegions);
    setSubRegions([]);
    setFormData((prevData) => ({
      ...prevData,
      State: selectedState,
      Region: "",
      SubRegion: "No Nested AVA",
    }));
  };
  

  const handleRegionChange = (e) => {
    const selectedRegion = e.target.value;
    setSelectedRegion(selectedRegion);
  
    const regionData = regions.find((region) => region.name === selectedRegion);
  
    // Sort subregions alphabetically
    const sortedSubRegions = regionData ? regionData.subRegions.sort((a, b) => a.localeCompare(b)) : [];
  
    setSubRegions(sortedSubRegions);
    setFormData((prevData) => ({
      ...prevData,
      Region: selectedRegion,
      SubRegion: sortedSubRegions.length ? "" : "No Nested AVA",
    }));
  };
  

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });

  const [autocomplete, setAutocomplete] = useState(null);
  const autocompleteRef = useRef(null);

  const onLoad = (autocomplete) => {
    autocompleteRef.current = autocomplete;
  };
  const DESCRIPTION_LIMIT = 150;

  const handleAddressChange = (event) => {
    setFormData({ ...formData, Address: event.target.value });
  };

  const onPlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place.geometry) {
        setFormData({
          ...formData,
          Address: place.formatted_address,
          Latitude: place.geometry.location.lat(),
          Longitude: place.geometry.location.lng(),
          Direction: `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
            place.formatted_address
          )}`,
        });
      }
    }
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleMobileChange = (e) => {
    const { name, value } = e.target;
  
    // Ensure the value always starts with "+1"
    let cleanedValue = value.replace(/\D/g, ''); // Remove any non-digit characters
    if (!cleanedValue.startsWith("1")) {
      cleanedValue = "1" + cleanedValue; // Add "1" if it doesn't start with "1"
    }
  
    // Limit to 11 digits after the "+1"
    cleanedValue = cleanedValue.substring(0, 11);
  
    // Set the value with "+1" prefix
    setFormData((prevData) => ({
      ...prevData,
      [name]: `+${cleanedValue}`,
    }));
  };
  
  
  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      const preview = URL.createObjectURL(file);
      setCurrentLogo(preview); // Set the preview image
      setFormData(prevData => ({
        ...prevData,
        shopLogo: file, // Set the file in formData
      }));
    } else {
      Swal.fire("Error!", "Only image files are allowed.", "error");
    }
  };

  const handleRemoveLogo = () => {
    setCurrentLogo(null);
    setFormData(prevData => ({
      ...prevData,
      shopLogo: null, // Clear the logo from formData
    }));
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
  
    // Filter out non-image files
    const validFiles = files.filter((file) =>
      file.type.startsWith("image/")
    );
  
    // Check if any invalid files were attempted to be uploaded
    if (validFiles.length !== files.length) {
      Swal.fire("Error!", "Only image files are allowed.", "error");
    }
  
    const totalImages = existingImages.length + newImages.length + validFiles.length;
  
    // Ensure the total number of images doesn't exceed 15
    if (totalImages > 15) {
      const allowedFiles = validFiles.slice(0, 15 - (existingImages.length + newImages.length));
      Swal.fire("Error!", `Sorry you can't upload more than 15 images  ${15 - (existingImages.length + newImages.length)} current limit .`, "error");
      
      const previews = allowedFiles.map((file) => URL.createObjectURL(file));
      setNewImages((prevImages) => [...prevImages, ...allowedFiles]);
      setNewImagePreviews((prevPreviews) => [...prevPreviews, ...previews]);
    } else {
      const previews = validFiles.map((file) => URL.createObjectURL(file));
      setNewImages((prevImages) => [...prevImages, ...validFiles]);
      setNewImagePreviews((prevPreviews) => [...prevPreviews, ...previews]);
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const token = localStorage.getItem("authToken");
      const formDataToSend = new FormData();

      for (const [key, value] of Object.entries(formData)) {
        if (Array.isArray(value)) {
          formDataToSend.append(key, value.join(","));
        } else {
          formDataToSend.append(key, value);
        }
      }

      existingImages.forEach((image) => {
        formDataToSend.append("existingImages", image); // Adjust if backend expects different structure
      });

      newImages.forEach((file) => {
        console.log("File to append:", file); // This will log each file in newImages
        formDataToSend.append("images", file);
      });
      

      formDataToSend.append("imagesToRemove", JSON.stringify(imagesToRemove)); // Send list of images to remove

      const response = await fetch(
        "https://timesavor-server.onrender.com/api/business/edit",
        {
          method: "PUT",
          headers: {
            "x-access-token": token,
          },
          body: formDataToSend,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update winery profile.");
      }

      const data = await response.json();
      setWineryData(data.shop);
      setIsEditing(false);
      setNewImages([]);
      setNewImagePreviews([]);
      fetchWineryData();
      Swal.fire("Success!", "Profile updated successfully.", "success");
    } catch (error) {
      Swal.fire("Error!", error.message, "error");
    } finally {
      setLoading(false);
    }
  };
  
  

  const removeImage = (index, type) => {
    if (type === "existing") {
      setImagesToRemove((prev) => [...prev, existingImages[index]]); // Add to remove list
      setExistingImages(existingImages.filter((_, i) => i !== index));
    } else {
      setNewImages(newImages.filter((_, i) => i !== index));
      setNewImagePreviews(newImagePreviews.filter((_, i) => i !== index));
    }
  };

  const openModal = (image) => {
    setModalImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


 

  const handleBusinessHoursChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      ShopTime: value,
    }));
  };


  const formatTime = (time) => {
    // Split the time into hours and minutes, preserving AM/PM
    const [timePart, period] = time.split(" ");
    let [hour, minute] = timePart.split(":").map(Number);

    // Convert to 12-hour format if necessary
    if (period === "PM" && hour !== 12) {
        hour += 12;
    } else if (period === "AM" && hour === 12) {
        hour = 0;
    }

    const formattedHour = hour % 12 || 12; // Convert hour to 12-hour format
    return `${formattedHour}:${minute.toString().padStart(2, "0")} ${period}`;
};

const formatBusinessHours = (hours) => {
    const dayMapping = {
        Mon: "Monday",
        Tue: "Tuesday",
        Wed: "Wednesday",
        Thu: "Thursday",
        Fri: "Friday",
        Sat: "Saturday",
        Sun: "Sunday",
    };

    return hours.split('; ').map(entry => {
        const [day, timeRange] = entry.split(', ');
        const dayFullName = dayMapping[day];
        const [startTime, endTime] = timeRange.split('-');
        return `${dayFullName}: ${formatTime(startTime)}-${formatTime(endTime)}`;
    }).join('\n');
};

  
  const handleShowDirection = () => {
    if (formData.Direction) {
      window.open(formData.Direction, "_blank");
    }
  };
  const handleMultiSelectChange = (field, selectedOptions) => {
    setFormData({ ...formData, [field]: selectedOptions });
  };

  const handleCancelButton = ()=>{
      setIsEditing(false);
      setNewImages([]);
      setNewImagePreviews([]);
  }

  if (!wineryData) {
    return <Spinner />;
  }

  return (
    <>
      <IndexNavbar />
      <div className="winery-profile-page">
        <div className="profile-card">
          <Carousel
            autoPlay
            infiniteLoop
            showThumbs={false}
            showStatus={false}
            showArrows={false}
          >
            {wineryData.Images.map((image, index) => (
              <div key={index}>
                <img
                  src={image}
                  alt={`Header ${index}`}
                  className="header-image"
                />
              </div>
            ))}
          </Carousel>
          <div className="profile-content">
            {isEditing ? (
              <form onSubmit={handleSubmit} className="edit-form">
                <div className="form-field">
                  <label htmlFor="Heading" className="form-label">
                    Winery Name
                  </label>
                  <input
                    type="text"
                    id="Heading"
                    name="Heading"
                    value={formData.Heading}
                    onChange={handleInputChange}
                    className="form-input"
                    placeholder="Winery name"
                  />
                </div>
                <div className="form-field">
                  <label htmlFor="Address" className="form-label">
                    Address
                  </label>
                  <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                    <input
                      type="text"
                      id="Address"
                      name="Address"
                      value={formData.Address}
                      onChange={handleAddressChange}
                      className="form-input"
                      placeholder="Address"
                      required
                    />
                  </Autocomplete>
                </div>
                <div className="form-field">
                  <label htmlFor="Direction" className="form-label">
                    Direction
                  </label>
                  <input
                    type="text"
                    id="Direction"
                    name="Direction"
                    value={formData.Direction}
                    onChange={handleInputChange}
                    className="form-input"
                    placeholder="Direction"
                  />
                  <button
                    type="button"
                    onClick={handleShowDirection}
                    className="show-direction-button"
                  >
                    Show Direction
                  </button>
                </div>
                <div className="form-field">
  <label htmlFor="Mob" className="form-label">
    Phone
  </label>
  <input
    type="tel"
    id="Mob"
    name="Mob"
    value={formData.Mob}
    onChange={handleMobileChange}
    className="form-input"
    placeholder="Phone"
    maxLength="15"
  />
</div>

<div className="form-field">
  <label htmlFor="Description" className="form-label">
    About
  </label>
  <textarea
    id="Description"
    name="Description"
    value={formData.Description}
    onChange={handleInputChange}
    className="form-input"
    placeholder="Description"
    maxLength={DESCRIPTION_LIMIT} // Set the maxLength directly on the input
  />
   <div className="char-count right-aligned">
    {formData.Description.length}/{DESCRIPTION_LIMIT} 
  </div>
</div>
                <div className="form-field">
                <label htmlFor="ShopTime" className="form-label">
                  Business Hours
                </label>
                <BusinessHours
                  value={formData.ShopTime}
                  onChange={handleBusinessHoursChange}
                />
              </div>
                <div className="form-field">
                  <label htmlFor="DeliverWithinMinutes" className="form-label">
                    Experience Duration (minutes)
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    id="DeliverWithinMinutes"
                    name="DeliverWithinMinutes"
                    value={formData.DeliverWithinMinutes}
                    onChange={handleInputChange}
                    className="form-input"
                    placeholder="Deliver Within (minutes)"
                  />
                </div>
                <div className="form-field">
                  <label htmlFor="ShopWebsiteURL" className="form-label">
                    Winery Website URL
                  </label>
                  <input
                    type="text"
                    id="ShopWebsiteURL"
                    name="ShopWebsiteURL"
                    value={formData.ShopWebsiteURL}
                    onChange={handleInputChange}
                    className="form-input"
                    placeholder="Shop Website URL"
                  />
                </div>

                <div className="form-field">
                  <label htmlFor="Price" className="form-label">
                    Tasting Fee ($)
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    id="Price"
                    name="Price"
                    value={formData.Price}
                    onChange={handleInputChange}
                    className="form-input"
                    placeholder="tasting fee ($)"
                  />
                </div>
                <div className="form-field">
                  <label htmlFor="State" className="form-label">
                    State
                  </label>
                  <select
                    id="State"
                    name="State"
                    value={formData.State}
                    onChange={(e) => {
                      handleStateChange(e);
                      handleInputChange(e);
                    }}
                    className="form-input"
                  >
                    <option value="">Select State</option>
                    {cityStateData.map((state) => (
                      <option key={state._id} value={state.state}>
                        {state.state}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-field">
                 
                  <select
                    id="Region"
                    name="Region"
                    value={formData.Region}
                    onChange={(e) => {
                      handleRegionChange(e);
                      handleInputChange(e);
                    }}
                    className="form-input"
                    disabled={!regions.length}
                  >
                    <option value="">Select American Viticultural Area</option>
                    {regions.map((region) => (
                      <option key={region._id} value={region.name}>
                        {region.name}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="Region" className="form-label">
                    American Viticultural Area
                  </label>
                </div>
                <div className="form-field">
                
                  <select
                    id="SubRegion"
                    name="SubRegion"
                    value={formData.SubRegion}
                    onChange={handleInputChange}
                    className="form-input"
                    disabled={!subRegions.length}
                  >
                    <option value="No Sub Region">Select Nested AVA</option>
                    {subRegions.map((subRegion, index) => (
                      <option key={index} value={subRegion}>
                        {subRegion}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="SubRegion" className="form-label">
                  Nested AVA
                  </label>
                </div>
                <div className="form-field" >
                <MultiSelect
                  options={categoryTypesOptions}
                  className="form-input"
                  value={formData.CategoryTypes}
                  onChange={(selectedOptions) =>
                    handleMultiSelectChange("CategoryTypes", selectedOptions)
                  }
                  isOpen={openMultiSelect === "CategoryTypes"}
                  toggleDropdown={() => handleMultiSelectToggle("CategoryTypes")}

                />
                 <label htmlFor="categoryTypes" className="form-label">Experience</label>
                </div>
                <div className="form-field" >
                <MultiSelect
                  options={suitabilityOptions}
                  className="form-input"
                  value={formData.Suitability}
                  onChange={(selectedOptions) =>
                    handleMultiSelectChange("Suitability", selectedOptions)
                  }
                  isOpen={openMultiSelect === "Suitability"}
                  toggleDropdown={() => handleMultiSelectToggle("Suitability")}
                  style={{height : "200px"}}
                />
                 <label htmlFor="suitability" className="form-label">Suitability</label>
                </div>
                
                <div className="form-field" >
                <MultiSelect
                  options={attractionsOptions}
                  className="form-input"
                  value={formData.Attractions}
                  onChange={(selectedOptions) =>
                    handleMultiSelectChange("Attractions", selectedOptions)
                  }
                  isOpen={openMultiSelect === "Attractions"}
                  toggleDropdown={() => handleMultiSelectToggle("Attractions")}
                  label="Attractions"
                />
                   <label htmlFor="attractions" className="form-label">Attractions</label>
                  </div>
                  <div className="form-field" >
                <MultiSelect
                  options={farmingOptions}
                  value={formData.Farming}
                  className="form-input"
                  onChange={(selectedOptions) =>
                    handleMultiSelectChange("Farming", selectedOptions)
                  }
                  isOpen={openMultiSelect === "Farming"}
                  toggleDropdown={() => handleMultiSelectToggle("Farming")}
                  // label="Farming"
                />
                 <label htmlFor="farming" className="form-label">Farming/Winemaking</label>
                 </div>
                 <div className="form-field" >
                <MultiSelect
                  options={grapeVarietalsOptions}
                  value={formData.GrapeVarietals}
                  className="form-input"
                  onChange={(selectedOptions) =>
                    handleMultiSelectChange("GrapeVarietals", selectedOptions)
                  }
                  isOpen={openMultiSelect === "GrapeVarietals"}
                  toggleDropdown={() => handleMultiSelectToggle("GrapeVarietals")}
                
               
                />
                 <label htmlFor="grapeVarietals" className="form-label">Grape Varietals and Wine Styles</label>
                 </div>

              
                <div className="form-field">
                  {/* <label htmlFor="Latitude" className="form-label">Latitude</label> */}
                  <input
                    type="hidden"
                    id="Latitude"
                    name="Latitude"
                    value={formData.Latitude}
                    onChange={handleInputChange}
                    className="form-input"
                    placeholder="Latitude"
                  />
                </div>
                <div className="form-field">
                  {/* <label htmlFor="Longitude" className="form-label">Longitude</label> */}
                  <input
                    type="hidden"
                    id="Longitude"
                    name="Longitude"
                    value={formData.Longitude}
                    onChange={handleInputChange}
                    className="form-input"
                    placeholder="Longitude"
                  />
                </div>
               

 




  <div className="form-field">
    <label htmlFor="FacebookURL" className="form-label">Facebook URL</label>
    <input
      type="text"
      id="FacebookURL"
      name="FacebookURL"
      value={formData.FacebookURL}
      onChange={handleInputChange}
      className="form-input"
      placeholder="Facebook URL"
    />
  </div>

  <div className="form-field">
    <label htmlFor="InstagramURL" className="form-label">Instagram URL</label>
    <input
      type="text"
      id="InstagramURL"
      name="InstagramURL"
      value={formData.InstagramURL}
      onChange={handleInputChange}
      className="form-input"
      placeholder="Instagram URL"
    />
  </div>

  <div className="form-field">
    <label htmlFor="TwitterURL" className="form-label">Twitter URL</label>
    <input
      type="text"
      id="TwitterURL"
      name="TwitterURL"
      value={formData.TwitterURL}
      onChange={handleInputChange}
      className="form-input"
      placeholder="Twitter URL"
    />
  </div>
  <div className="form-field">
  <label htmlFor="shopLogo" className="form-label">Winery Logo</label>
  <input
    type="file"
    id="shopLogo"
    name="shopLogo"
    accept="image/*"
    onChange={handleLogoChange} // Updated to use the handleLogoChange function
    className="form-input"
  />
  {/* {currentLogo && (
    <div className="logo-preview-container">
      <img 
        src={currentLogo} 
        alt="Shop Logo Preview" 
        className="preview-image small-logo"
      />
      <button 
        type="button" 
        className="remove-logo-button"
        onClick={handleRemoveLogo} // Updated to use the handleRemoveLogo function
      >
        Remove Logo
      </button>
    </div>
  )} */}

{currentLogo && (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      mt: 2,
      position: 'relative',
      width: 100,
      height: 100,
      cursor: 'pointer',
      '&:hover .image-preview-text': {
        opacity: 1,
        color: 'white',
      },
      '&:hover img': {
        opacity: 0.6,
        filter: 'brightness(0.5)',
      },
    }}
  >
    <img
      src={currentLogo}
      alt="Shop Logo"
      style={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: '4px',
        opacity: 1,
        transition: 'opacity 0.3s ease, filter 0.3s ease',
      }}
      onClick={() => openModal(currentLogo)}
    />

    <IconButton
      size="small"
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        bgcolor: 'background.paper',
        '&:hover': {
          bgcolor: 'white',
        },
      }}
      onClick={handleRemoveLogo}
    >
      <AiFillDelete fontSize="medium" style={{ color: '#666' }} className="delete-icon" /> {/* Default color */}
    </IconButton>

    <Box
      className="image-preview-text"
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: 'white',
        fontSize: '16px',
        fontWeight: 'bold',
        opacity: 0,
        transition: 'opacity 0.3s ease, color 0.3s ease',
      }}
      onClick={() => openModal(currentLogo)}
    >
      Preview
    </Box>
  </Box>
)}

</div>
                <div className="form-field">
      <label htmlFor="Images" className="form-label">
        Winery Images
      </label>
      <input
        type="file"
        id="Images"
        name="images"
        accept=".jpg, .jpeg, .png, .gif, .bmp, .tiff, .webp, .svg, .ico, .heic"
        multiple
        onChange={handleImageChange}
        className="form-input"
      />
      <div className="image-count">
        Total Images: {existingImages.length + newImages.length} / 15
      </div>
      {/* <div className="image-previews">
        {existingImages.map((image, index) => (
          <div key={index} className="image-preview">
            <img
              src={image}
              alt={`Existing ${index}`}
              className="preview-image"
            />
            <button
              type="button"
              onClick={() => removeImage(index, "existing")}
              className="remove-image-button"
            >
              &times;
            </button>
            <i
              className="fas fa-eye view-image-button"
              onClick={() => openModal(image)}
            ></i>
          </div>
        ))}
        {newImagePreviews.map((preview, index) => (
          <div key={index} className="image-preview">
            <img
              src={preview}
              alt={`Selected ${index}`}
              className="preview-image"
            />
            <button
              type="button"
              onClick={() => removeImage(index, "new")}
              className="remove-image-button"
            >
              &times;
            </button>
            <i
              className="fas fa-eye view-image-button"
              onClick={() => openModal(preview)}
            ></i>
          </div>
        ))}
      </div> */}

<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>
{existingImages.map((image, index) => (
  // <Box key={index} sx={{ position: 'relative' }}>
  //   <img
  //     src={image}
  //     alt={`Edited ${index}`}
  //     style={{
  //       width: 100,
  //       height: 100,
  //       objectFit: 'cover',
  //       borderRadius: '4px',
  //       transition: 'opacity 0.3s ease, filter 0.3s ease', // Smooth transition for opacity and filter
  //     }}
  //     onClick={() => openModal(image)} // Open modal for preview
  //   />

  //   {/* Delete Button */}
  //   <IconButton
  //     size="small"
  //     sx={{
  //       position: 'absolute',
  //       top: 0,
  //       right: 0,
  //       bgcolor: 'background.paper',
  //       '&:hover': {
  //         bgcolor: 'white',
  //         '& .MuiSvgIcon-root': {
  //           color: '#800028', // Changes trash icon color to red on hover
  //         },
  //       },
  //     }}
  //     onClick={() => removeImage(index, "existing")} // Remove the image
  //   >
  //     <DeleteIcon fontSize="small" /> {/* Trash icon */}
  //   </IconButton>

  //   {/* Preview Text in the center of the image */}
  //   <Box
  //     sx={{
  //       position: 'absolute',
  //       top: '50%',  // Center vertically
  //       left: '50%',  // Center horizontally
  //       transform: 'translate(-50%, -50%)',  // Adjust to truly center
  //       color: 'white',
  //       fontSize: '16px',
  //       fontWeight: 'bold',
  //       opacity: 0,  // Initially hidden
  //       transition: 'opacity 0.3s ease',  // Smooth transition for text visibility
  //     }}
  //   >
  //     Preview
  //   </Box>
  // </Box>

  <Box
  sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',  // Center content horizontally
    mt: 2,
    position: 'relative',
    width: 100,
    height: 100,
    cursor: 'pointer',  // Change cursor to pointer on hover
    '&:hover .image-preview-text': {
      opacity: 1,  // Show the text on hover
      color: 'white',  // Darken the text color on hover
    },
    '&:hover img': {
      opacity: 0.6,  // Reduce opacity of the image on hover to dim it
      filter: 'brightness(0.5)',  // Dim the image on hover
    },
  }}
>
  <img
    src={image}
    alt="Shop Logo"
    style={{
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '4px',
      opacity: 1,  // Default opacity of the image
      transition: 'opacity 0.3s ease, filter 0.3s ease',  // Smooth transition for opacity and filter changes
    }}
    onClick={() => openModal(image)}  // Open modal on image click
  />

  <IconButton
    size="small"
    sx={{
      position: 'absolute',
      top: 0,
      right: 0,
      bgcolor: 'background.paper',
      '&:hover': {
        bgcolor: 'white',
        '& .MuiSvgIcon-root': {
          color: '#800028',  // Changes trash icon color to red on hover
        },
      },
    }}
    onClick={() => removeImage(index, "existing")}  // Remove the selected logo
  >
    <AiFillDelete fontSize="medium" style={{ color: '#666' }} className="delete-icon"  />  {/* Trash icon */}
  </IconButton>

  {/* Preview text in the center */}
  <Box
    className="image-preview-text"
    sx={{
      position: 'absolute',
      top: '50%',  // Center vertically
      left: '50%',  // Center horizontally
      transform: 'translate(-50%, -50%)',  // Adjust to truly center
      color: 'white',  // Default text color
      fontSize: '16px',
      fontWeight: 'bold',
      color : "white",
      opacity: 0,  // Initially hidden
      transition: 'opacity 0.3s ease, color 0.3s ease',  // Smooth transition for text visibility and color
    }}
    onClick={() => openModal(image)}
  >
    Preview
  </Box>
</Box>

))}

{newImagePreviews.map((image, index) => (
  // <Box key={index} sx={{ position: 'relative' }}>
  //   <img
  //     src={image}
  //     alt={`Edited ${index}`}
  //     style={{
  //       width: 100,
  //       height: 100,
  //       objectFit: 'cover',
  //       borderRadius: '4px',
  //       transition: 'opacity 0.3s ease, filter 0.3s ease', // Smooth transition for opacity and filter
  //     }}
  //     onClick={() => openModal(image)} // Open modal for preview
  //   />

  //   {/* Delete Button */}
  //   <IconButton
  //     size="small"
  //     sx={{
  //       position: 'absolute',
  //       top: 0,
  //       right: 0,
  //       bgcolor: 'background.paper',
  //       '&:hover': {
  //         bgcolor: 'white',
  //         '& .MuiSvgIcon-root': {
  //           color: '#800028', // Changes trash icon color to red on hover
  //         },
  //       },
  //     }}
  //     onClick={() => removeImage(index, "new")} // Remove the image
  //   >
  //     <DeleteIcon fontSize="small" /> {/* Trash icon */}
  //   </IconButton>

  //   {/* Preview Text in the center of the image */}
  //   <Box
  //     sx={{
  //       position: 'absolute',
  //       top: '50%',  // Center vertically
  //       left: '50%',  // Center horizontally
  //       transform: 'translate(-50%, -50%)',  // Adjust to truly center
  //       color: 'white',
  //       fontSize: '16px',
  //       fontWeight: 'bold',
  //       opacity: 0,  // Initially hidden
  //       transition: 'opacity 0.3s ease',  // Smooth transition for text visibility
  //     }}
  //   >
  //     Preview
  //   </Box>
  // </Box>
  <Box
  sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',  // Center content horizontally
    mt: 2,
    position: 'relative',
    width: 100,
    height: 100,
    cursor: 'pointer',  // Change cursor to pointer on hover
    '&:hover .image-preview-text': {
      opacity: 1,  // Show the text on hover
      color: 'white',  // Darken the text color on hover
    },
    '&:hover img': {
      opacity: 0.6,  // Reduce opacity of the image on hover to dim it
      filter: 'brightness(0.5)',  // Dim the image on hover
    },
  }}
>
  <img
    src={image}
    alt="Shop Logo"
    style={{
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '4px',
      opacity: 1,  // Default opacity of the image
      transition: 'opacity 0.3s ease, filter 0.3s ease',  // Smooth transition for opacity and filter changes
    }}
    onClick={() => openModal(image)}  // Open modal on image click
  />

  <IconButton
    size="small"
    sx={{
      position: 'absolute',
      top: 0,
      right: 0,
      bgcolor: 'background.paper',
      '&:hover': {
        bgcolor: 'white',
        '& .MuiSvgIcon-root': {
          color: '#800028',  // Changes trash icon color to red on hover
        },
      },
    }}
    onClick={() => removeImage(index, "new")}
  >
    <AiFillDelete fontSize="medium" style={{ color: '#666' }} className="delete-icon" />  {/* Trash icon */}
  </IconButton>

  {/* Preview text in the center */}
  <Box
    className="image-preview-text"
    sx={{
      position: 'absolute',
      top: '50%',  // Center vertically
      left: '50%',  // Center horizontally
      transform: 'translate(-50%, -50%)',  // Adjust to truly center
      color: 'white',  // Default text color
      fontSize: '16px',
      fontWeight: 'bold',
      color : "white",
      opacity: 0,  // Initially hidden
      transition: 'opacity 0.3s ease, color 0.3s ease',  // Smooth transition for text visibility and color
    }}
    onClick={() => openModal(image)}
  >
    Preview
  </Box>
</Box>

))}


    </Box>
 
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
        className="image-modal"
        overlayClassName="image-modal-overlay"
      >
        <button onClick={closeModal} className="close-modal-button" >
          &times;
        </button>
        <img src={modalImage} alt="Full View" className="modal-image" />
      </ReactModal>
    </div>
           <div style={{display : "flex" , justifyContent : "space-between"}}>
                <button
                  type="submit"
                  className="submit-button"
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <div className="button-loader"></div>
                      <span>Please wait while we are updating...</span>
                    </>
                  ) : (
                    "Save Changes"
                  )}
                </button>
                <button onClick={handleCancelButton} className="button-style">Cancel</button>

           </div>
              </form>
            ) : (
              <>
                <img
                  src={wineryData.shopLogo}
                  alt="Profile"
                  className="profile-picture"
                />
                <h1 className="profile-title">{wineryData.Heading}</h1>
                <p className="profile-subtitle">
                 Winery Profile Approval Status :{" "}
                  <span
                    style={{
                      fontWeight: "600",
                      color: wineryData.Status === "Pending" ? "red" : "green",
                      backgroundColor:
                        wineryData.Status === "Pending" ? "#ffe6e6" : "#e6ffe6",
                      padding: "5px 10px",
                      borderRadius: "5px",
                      fontSize: "1.1em",
                    }}
                  >
                    {wineryData.Status}
                  </span>
                </p>
                <div className="profile-section">
                  <h2 className="section-title">Basic Information</h2>
                  <div className="profile-field">
                    <div className="field-label">Email:</div>
                    <div className="field-value">{wineryData.email}</div>
                  </div>
                  <div className="profile-field">
                    <div className="field-label">Address:</div>
                    <div className="field-value">{wineryData.Address}</div>
                  </div>
                  <div className="profile-field">
                    <div className="field-label">Direction:</div>
                    <div className="field-value">
                      <button
                        onClick={() =>
                          window.open(wineryData.Direction, "_blank")
                        }
                        className="show-direction-button"
                      >
                        Show Direction 📍
                      </button>
                    </div>
                  </div>
                  <div className="profile-field">
                    <div className="field-label">Phone:</div>
                    <div className="field-value">{wineryData.Mob}</div>
                  </div>
                  <div className="profile-field">
  <div className="field-label">Business Hours:</div>
  <div className="field-value">
    <div style={{ whiteSpace: 'pre-line' }}>
      {formatBusinessHours(wineryData.ShopTime).split('\n').map((line, index) => (
        <div key={index} className="business-hour-line">
          <span className="business-day">{line.split(': ')[0]}</span>
          <span className="business-time">{line.split(': ')[1]}</span>
        </div>
      ))}
    </div>
  </div>
</div>


                  <div className="profile-field">
                    <div className="field-label">
                      Experience Duration (minutes):
                    </div>
                    <div className="field-value">
                      {wineryData.DeliverWithinMinutes} minutes
                    </div>
                  </div>

                  <div className="profile-field">
                    <div className="field-label">Winery Website URL:</div>
                    <div className="field-value">
                      <a
                        href={wineryData.ShopWebsiteURL}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {wineryData.ShopWebsiteURL}
                      </a>
                    </div>
                  </div>
                  {/* <div className="profile-field">
                    <div className="field-label">Category Types:</div>
                    <div className="field-value">
                      {Array.isArray(wineryData.CategoryTypes)
                        ? wineryData.CategoryTypes.join(", ")
                        : wineryData.CategoryTypes}
                    </div>
                  </div> */}
                  

                  <div className="profile-field">
                    <div className="field-label">Tasting Fee ($):</div>
                    <div className="field-value">{wineryData.Price}</div>
                  </div>
                  <div className="profile-field">
                    <div className="field-label">State:</div>
                    <div className="field-value">{wineryData.State}</div>
                  </div>
                  <div className="profile-field">
                    <div className="field-label">American Viticultural Area:</div>
                    <div className="field-value">{wineryData.Region}</div>
                  </div>
                  <div className="profile-field">
                    <div className="field-label">Nested AVA:</div>
                    <div className="field-value">
                      {wineryData.SubRegion || "No Nested AVA"}
                    </div>
                  </div>
                  {/* <div className="profile-field">
                    <div className="field-label">Suitability:</div>
                    <div className="field-value">
                      {Array.isArray(wineryData.Suitability)
                        ? wineryData.Suitability.join(", ")
                        : wineryData.Suitability}
                    </div>
                  </div>
                  <div className="profile-field">
                    <div className="field-label">Attractions:</div>
                    <div className="field-value">
                      {Array.isArray(wineryData.Attractions)
                        ? wineryData.Attractions.join(", ")
                        : wineryData.Attractions}
                    </div>
                  </div>
                  <div className="profile-field">
                    <div className="field-label">Farming:</div>
                    <div className="field-value">
                      {Array.isArray(wineryData.Farming)
                        ? wineryData.Farming.join(", ")
                        : wineryData.Farming}
                    </div>
                  </div>
                  <div className="profile-field">
                    <div className="field-label">Grape Varietals:</div>
                    <div className="field-value">
                      {Array.isArray(wineryData.GrapeVarietals)
                        ? wineryData.GrapeVarietals.join(", ")
                        : wineryData.GrapeVarietals}
                    </div>
                  </div> */}
                  <div className="profile-field">
  <div className="field-label">Experience:</div>
  <div className="field-value">
    {Array.isArray(wineryData.CategoryTypes) && wineryData.CategoryTypes.length > 0 
      ? wineryData.CategoryTypes.join(", ") 
      : '-'}
  </div>
</div>
                  <div className="profile-field">
  <div className="field-label">Suitability:</div>
  <div className="field-value">
    {Array.isArray(wineryData.Suitability) && wineryData.Suitability.length > 0 
      ? wineryData.Suitability.join(", ") 
      : '-'}
  </div>
</div>
<div className="profile-field">
  <div className="field-label">Attractions:</div>
  <div className="field-value">
    {Array.isArray(wineryData.Attractions) && wineryData.Attractions.length > 0 
      ? wineryData.Attractions.join(", ") 
      : '-'}
  </div>
</div>
<div className="profile-field">
  <div className="field-label">Farming:</div>
  <div className="field-value">
    {Array.isArray(wineryData.Farming) && wineryData.Farming.length > 0 
      ? wineryData.Farming.join(", ") 
      : '-'}
  </div>
</div>
<div className="profile-field">
  <div className="field-label">Grape Varietals:</div>
  <div className="field-value">
    {Array.isArray(wineryData.GrapeVarietals) && wineryData.GrapeVarietals.length > 0 
      ? wineryData.GrapeVarietals.join(", ") 
      : '-'}
  </div>
</div>

                  <div className="profile-field">
                    <div className="field-label">Facebook:</div>
                    <div className="field-value"> {wineryData.FacebookURL ? wineryData.FacebookURL : "-"}</div>
                  </div>
                  <div className="profile-field">
                    <div className="field-label">Twitter:</div>
                    <div className="field-value">{wineryData.TwitterURL ? wineryData.TwitterURL : "-"}</div>
                  </div>
                  <div className="profile-field">
                    <div className="field-label">Instagram:</div>
                    <div className="field-value">{wineryData.InstagramURL ? wineryData.InstagramURL : "-"}</div>
                  </div>

                </div>
              
                <div className="profile-section">
  <h2 className="section-title">Preview Wineries</h2>
  
  <div className="images" style={{ display: 'flex', flexWrap: 'wrap' }}>
    {wineryData.Images.map((image, index) => (
      <Box
        key={index} // Add a unique key for each element
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyConten:"space-between",
          position: 'relative',
          width: 'calc(30.33% - 16px)', // Set each box to one-third width minus some margin
          height: 250,
          gap:"20px",
          margin: '8px', // Add margin between images
          cursor: 'pointer',
          '&:hover .image-preview-text': {
            opacity: 1,
            color: 'white',
          },
          '&:hover img': {
            opacity: 0.6,
            filter: 'brightness(0.5)',
          },
        }}
      >
        <img
          src={image}
          alt="Shop Logo"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '4px',
            opacity: 1,
            transition: 'opacity 0.3s ease, filter 0.3s ease',
          }}
          onClick={() => openModal(image)}
        />
        
        <Box
          className="image-preview-text"
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
            fontSize: '16px',
            fontWeight: 'bold',
            opacity: 0,
            transition: 'opacity 0.3s ease, color 0.3s ease',
          }}
          onClick={() => openModal(image)}
        >
          Preview
        </Box>
      </Box>
    ))}
  </div>
  
  <ReactModal
    isOpen={isModalOpen}
    onRequestClose={closeModal}
    contentLabel="Image Modal"
    className="image-modal"
    overlayClassName="image-modal-overlay"
  >
    <button onClick={closeModal} className="close-modal-button">
      &times;
    </button>
    <img src={modalImage} alt="Full View" className="modal-image" />
  </ReactModal>
</div>


                <button
                  style={{ backgroundColor: "#800020" }}
                  onClick={handleEditClick}
                  className="edit-button"
                >
                  {isEditing ? "Cancel" : "Edit Profile"}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <div
        style={{ backgroundColor: "#E6E6E6", width: "full", height: "1px" }}
      ></div>
      <Footer />
    </>
  );
};

export default WineryProfile;
