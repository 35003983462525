import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";
import TermsAndConditions from "./views/TermsAndConditions";
import PrivacyPolicy from "./views/PrivacyPolicy";
// layouts
import Profile from "views/Profile";
import WineryProfile from "views/Wineryprofile.js";
import Admin from "layouts/Admin.js";
import Cookies from "views/CookiesPolicy";
import Auth from "layouts/Auth.js";
import LoginPage from "views/auth/Login";
import ForgotPassword from "views/auth/ForgotPassword";
import Register from "views/auth/Register";
import OtpVerification from "views/auth/OtpVerification";
import About from "./views/About"
import Howitworks from "./views/Howitworks";
import Contact from "./views/Contact"
// views without layouts

import Index from "views/Index.js";
// import MaintenanceMode from "./views/MaintenanceMode";  // Import the MaintenanceMode component

// components
import Spinner from "./components/Spinner/Spinner";
import { AuthProvider } from 'AuthContext'; // Import AuthProvider

const App = () => {
  const [loading, setLoading] = useState(true);
  // const maintenanceMode = false;

  useEffect(() => {
    // Simulate a delay to show the spinner
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <Spinner />;
  }

  // if (maintenanceMode) {
  //   return <MaintenanceMode />;
  // }

  return (
    <AuthProvider>
      <BrowserRouter>
        <Switch>
          {/* add routes with layouts */}
          <Route path="/admin" component={Admin} />
          <Route path="/auth" component={Auth} />
          {/* add routes without layouts */}
          <Route path="/LoginPage" exact component={LoginPage}/>
          <Route path="/Register" exact component={Register}/>
          <Route path="/ForgotPassword" exact component={ForgotPassword}/>
          {/* <Route path="/privacypolicy" component={() => {
          window.location.href = "../public/privacy.html";
          return null;
        }} /> */}
          <Route path="/profile" exact component={Profile} /> 
          <Route path="/winery" exact component={WineryProfile}/>
          
          <Route path="/" exact component={Index} />
          <Route path="/otpVerification" exact component={OtpVerification}/>
          <Route path="/terms" exact component={TermsAndConditions} />
          <Route path="/privacypolicy" exact component={PrivacyPolicy} />
          <Route path="/About" exact component={About} />
          <Route path="/Howitworks" exact component={Howitworks} />
          <Route path="/Contact" exact component={Contact}/>
          <Route path= '/cookiepolicy' exact component = {Cookies} />
        
          <Redirect from="*" to="/" />
        </Switch>
      </BrowserRouter>
    </AuthProvider>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
