import React, { useState, useEffect } from 'react';
import './hours.css';

const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const timeSlots = Array.from({ length: 24 * 2 }, (_, i) => {
  const hours = Math.floor(i / 2);
  const minutes = i % 2 === 0 ? '00' : '30';
  const period = hours < 12 ? 'AM' : 'PM';
  const displayHours = hours % 12 === 0 ? 12 : hours % 12;
  return `${String(displayHours).padStart(2, '0')}:${minutes} ${period}`;
});

const BusinessHours = ({ value, onChange }) => {
  const initialState = value
    ? Object.fromEntries(
        value.split(';').map(entry => {
          const [dayRange, timeRange] = entry.split(', ');
          const days = dayRange.split('-');
          const [openTime, closeTime] = timeRange.split('-');
          return days.map(day => [day, { openTime, closeTime }]);
        }).flat()
      )
    : {};

  const [selectedDays, setSelectedDays] = useState(initialState);
  const [commonTime, setCommonTime] = useState(null);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const newValue = Object.entries(selectedDays)
      .filter(([, times]) => times?.openTime && times?.closeTime)
      .map(([day, { openTime, closeTime }]) => `${day}, ${openTime}-${closeTime}`)
      .join('; ');

    onChange(newValue);
  }, [selectedDays, onChange]);

  const toggleDaySelection = (day) => {
    setSelectedDays(prevState => ({
      ...prevState,
      [day]: prevState[day] ? undefined : { openTime: '', closeTime: '' },
    }));
    setErrors(prevErrors => ({ ...prevErrors, [day]: undefined }));
  };

  const convertTo24Hour = (time12h) => {
    const [time, modifier] = time12h.split(' ');
    let [hours, minutes] = time.split(':');
    
    hours = String(hours);  // Ensure hours is a string
    
    if (hours === '12') {
      hours = '00';
    }
    if (modifier === 'PM') {
      hours = String(parseInt(hours, 10) + 12);
    }
    return `${hours.padStart(2, '0')}${minutes}`;
  };

  const handleTimeChange = (day, timeType, timeValue) => {
    setSelectedDays(prevState => {
      const updatedDay = {
        ...prevState[day],
        [timeType]: timeValue,
      };

      let error = '';
      const startTime24 = convertTo24Hour(updatedDay.openTime);
      const endTime24 = convertTo24Hour(updatedDay.closeTime);

      if (timeType === 'openTime' && updatedDay.closeTime) {
        if (startTime24 >= endTime24) {
          error = "End time must be greater than start time.";
        } else if (!updatedDay.closeTime) {
          error = "Please select a closing time.";
        }
      } else if (timeType === 'closeTime' && updatedDay.openTime) {
        if (endTime24 <= startTime24) {
          error = "End time must be greater than start time.";
        } else if (!updatedDay.openTime) {
          error = "Please select an opening time.";
        }
      }

      setErrors(prevErrors => ({ ...prevErrors, [day]: error }));

      return {
        ...prevState,
        [day]: updatedDay,
      };
    });

    if (timeType === 'openTime' && selectedDays[day]?.closeTime) {
      setCommonTime({ openTime: timeValue, closeTime: selectedDays[day]?.closeTime });
    } else if (timeType === 'closeTime' && selectedDays[day]?.openTime) {
      setCommonTime({ openTime: selectedDays[day]?.openTime, closeTime: timeValue });
    }
  };

  const applyCommonTimeToSelectedDays = () => {
    if (commonTime) {
      setSelectedDays(prevState => {
        const newState = { ...prevState };
        for (const day of Object.keys(newState)) {
          if (newState[day]) {
            newState[day] = { ...commonTime };
          }
        }
        return newState;
      });
    }
  };

  const getFilteredEndTimes = (startTime) => {
    if (!startTime) return timeSlots;
    const startIndex = timeSlots.findIndex(time => time === startTime);
    return timeSlots.slice(startIndex + 1); // Exclude times that are equal to or earlier than the start time
  };

  return (
    <div className="business-hours">
      <div className="days-selector">
        {days.map(day => (
          <label key={day} className="day-checkbox">
            <input
              type="checkbox"
              value={day}
              checked={!!selectedDays[day]}
              onChange={() => toggleDaySelection(day)}
            />
            {day}
          </label>
        ))}
      </div>
      <div className="time-selector">
        {days.map(day => selectedDays[day] && (
          <div key={day} className="time-row">
            <span className="day-label">{day}:</span>
            <select
              value={selectedDays[day]?.openTime || ''}
              onChange={(e) => handleTimeChange(day, 'openTime', e.target.value)}
              className={`time-select ${errors[day] ? 'error' : ''}`}
            >
              <option value="">Select opening time</option>
              {timeSlots.map(time => (
                <option key={time} value={time}>{time}</option>
              ))}
            </select>
            <span className="time-to">To</span>
            <select
              value={selectedDays[day]?.closeTime || ''}
              onChange={(e) => handleTimeChange(day, 'closeTime', e.target.value)}
              className={`time-select ${errors[day] ? 'error' : ''}`}
            >
              <option value="">Select closing time</option>
              {getFilteredEndTimes(selectedDays[day]?.openTime).map(time => (
                <option key={time} value={time}>{time}</option>
              ))}
            </select>
            {errors[day] && <div className="error-message">{errors[day]}</div>}
          </div>
        ))}
      </div>
      {commonTime && (
        <div className="apply-to-all">
          <button className="apply-button" onClick={applyCommonTimeToSelectedDays}>
            Apply to All Selected Days
          </button>
        </div>
      )}
    </div>
  );
};

export default BusinessHours;
