import React, { useState, useEffect } from 'react';
import './hours.css';

const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const timeSlots = Array.from({ length: 24 * 2 }, (_, i) => {
  const hours = Math.floor(i / 2);
  const minutes = i % 2 === 0 ? '00' : '30';
  const period = hours < 12 ? 'AM' : 'PM';
  const displayHours = hours % 12 === 0 ? 12 : hours % 12;
  return `${String(displayHours).padStart(2, '0')}:${minutes} ${period}`;
});

const parseShopTime = (shopTime) => {
  if (!shopTime) return {};
  const timeEntries = shopTime.split('; ').map(entry => {
    const [day, timeRange] = entry.split(', ');
    const [openTime, closeTime] = timeRange.split('-');
    return [day, { openTime, closeTime }];
  });
  return Object.fromEntries(timeEntries);
};

const convertTo24Hour = (time12h) => {
  const [time, modifier] = time12h.split(' ');
  let [hours, minutes] = time.split(':');
  if (hours === '12') {
    hours = '00';
  }
  if (modifier === 'PM') {
    hours = String(parseInt(hours, 10) + 12);
  }
  return `${hours.padStart(2, '0')}:${minutes}`;
};

const getFilteredEndTimes = (startTime) => {
  if (!startTime) return timeSlots;
  const startIndex = timeSlots.findIndex(time => time === startTime);
  return timeSlots.slice(startIndex + 1); // Exclude times that are equal to or earlier than the start time
};

const BusinessHours = ({ value, onChange }) => {
  const [selectedDays, setSelectedDays] = useState(() => parseShopTime(value));

  useEffect(() => {
    const newValue = Object.entries(selectedDays)
      .filter(([, times]) => times?.openTime && times?.closeTime)
      .map(([day, { openTime, closeTime }]) => `${day}, ${openTime}-${closeTime}`)
      .join('; ');

    if (newValue !== value) {
      onChange(newValue);
    }
  }, [selectedDays, value, onChange]);

  const toggleDaySelection = (day) => {
    setSelectedDays(prevState => {
      const newState = { ...prevState };
      if (newState[day]) {
        delete newState[day]; // Unselect the day if it's already selected
      } else {
        newState[day] = { openTime: '', closeTime: '' }; // Select the day with empty times
      }
      return newState;
    });
  };

  const handleTimeChange = (day, timeType, timeValue) => {
    setSelectedDays(prevState => ({
      ...prevState,
      [day]: {
        ...prevState[day],
        [timeType]: timeValue,
      },
    }));
  };

  return (
    <div className="business-hours">
      <div className="days-selector">
        {days.map(day => (
          <label key={day} className="day-checkbox">
            <input
              type="checkbox"
              checked={!!selectedDays[day]}
              onChange={() => toggleDaySelection(day)}
            />
            {day}
          </label>
        ))}
      </div>
      <div className="time-selector">
        {days.map(day => selectedDays[day] && (
          <div key={day} className="time-row">
            <span className="day-label">{day}:</span>
            <select
              value={selectedDays[day]?.openTime || ''}
              onChange={(e) => handleTimeChange(day, 'openTime', e.target.value)}
              className="time-select"
            >
              <option value="">Select opening time</option>
              {timeSlots.map(time => (
                <option key={time} value={time}>{time}</option>
              ))}
            </select>
            <span className="time-to">To</span>
            <select
              value={selectedDays[day]?.closeTime || ''}
              onChange={(e) => handleTimeChange(day, 'closeTime', e.target.value)}
              className="time-select"
            >
              <option value="">Select closing time</option>
              {getFilteredEndTimes(selectedDays[day]?.openTime).map(time => (
                <option key={time} value={time}>{time}</option>
              ))}
            </select>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BusinessHours;
