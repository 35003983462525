import React, { useState, useEffect } from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function AboutUs() {
  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => setPageLoaded(true), 100);
  }, []);

  const pageTransition = `
    @keyframes pageTransition {
      from { opacity: 0; transform: translateY(20px); }
      to { opacity: 1; transform: translateY(0); }
    }
  `;

  const responsiveStyles = `
  @media (max-width: 768px) {
    .section1{
      background-size: 100% 100% !important;

    }
    .desktop {
      display: none;
    }
    
    .mobile{
      display: block;
      }
    .responsive-box {
      width: 75%; /* Adjusted for better width on mobile */
      margin-top: 20%;
      top: 68%; /* Center the box vertically */
      transform: translate(50%, -50%);
      right: 50%;
      max-height: 90vh; /* Ensure the box doesn't overflow the viewport */
      overflow-y: auto; /* Allow scrolling if content exceeds the height */
     
    }
    .heading {
      font-size: 2.7em;
      font-weight: 500;
      text-align: center;
      margin-right:9%;
    }
    .about-us-text {
      font-size: 0.62em;
      text-align: center;
      line-height: 0.1;
    }
  }

  @media (min-width: 769px) {
    .responsive-box {
      top: 25%;
      right: 11%;
      text-align: center;
      padding: 6px; /* Keep padding for larger screens */
    }
    .heading {
      font-size: 5em;
      text-align: right;
      position: absolute;
      font-weight: 800;
      right: 13%;
    }
    .about-us-text {
      font-size: 1.2em;
      text-align: center;
      line-height: 1.8;
    }
  }
`;

  return (
    <>
      <style>{pageTransition}</style>
      <style>{responsiveStyles}</style>
      <IndexNavbar />
      <section
        className={`relative py-20 bg-gray-100 section1 desktop ${
          pageLoaded ? "animate-pageTransition" : ""
        }`}
        style={{
          animation: pageLoaded ? "pageTransition 0.5s ease-out" : "none",
          opacity: pageLoaded ? 1 : 0,
          transform: pageLoaded ? "translateY(0)" : "translateY(20px)",
          transition: "opacity 0.5s ease-out, transform 0.5s ease-out",
          height: "110vh",
          width: "100vw",
          margin: 0,
          // borderRadius: "20px",
          padding: 0,
          textAlign: "center",
          backgroundImage: `url("https://res.cloudinary.com/dtpevqqw5/image/upload/v1724850172/ayypdqxxshu9r3sd4bfs.png")`,
          backgroundSize: "cover",
          backgroundPosition: "12% center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          overflow: "hidden",
        }}
      >
        <div
          className="absolute"
          style={{
            top: "10%",
            width: "100%",
            textAlign: "right",
            right: "-4%",
          }}
        >
          <h1
            className="heading"
            style={{
              color: "#800020",
              fontFamily: "Baskervville, serif",
            }}
          >
            About TimeSavor
          </h1>
        </div>
        <div
          className="absolute responsive-box"
          style={{
            padding: "6px",
            opacity: "0.85",
            borderRadius: "20px",
            backgroundColor: "rgba(195, 195, 195, 0.686)",
            backdropFilter: "blur(1px)",
            color: "black",
          }}
        >
          <div>
            <p
              className="about-us-text"
              style={{
                color: "#800020",
                lineHeight: "1.2",
                marginBottom: "8px",
                fontFamily: "Montserrat, sans-serif",
              }}
            >
              We believe time is like wine.
            </p>
            <p
              className="about-us-text"
              style={{
                color: "#800020",
                lineHeight: "1.2",
                marginBottom: "8px",
                fontFamily: "Montserrat, sans-serif",
              }}
            >
              It is meant to be shared and savored.
            </p>
            <p
              className="about-us-text"
              style={{
                color: "#800020",
                lineHeight: "1.2",
                marginBottom: "8px",
                fontFamily: "Montserrat, sans-serif",
              }}
            >
              Our mission: simplify the planning
            </p>
            <p
              className="about-us-text"
              style={{
                color: "#800020",
                lineHeight: "1.2",
                marginBottom: "8px",
                fontFamily: "Montserrat, sans-serif",
              }}
            >
              of your trip to wine country so you can savor
            </p>
            <p
              className="about-us-text"
              style={{
                color: "#800020",
                lineHeight: "1.2",
                marginBottom: "8px",
                fontFamily: "Montserrat, sans-serif",
              }}
            >
              every minute of it.
            </p>
            <p
              className="about-us-text"
              style={{
                color: "#800020",
                lineHeight: "1.2",
                marginBottom: "8px",
                fontFamily: "Montserrat, sans-serif",
              }}
            >
              TimeSavor is a tech company that connects the
            </p>
            <p
              className="about-us-text"
              style={{
                color: "#800020",
                lineHeight: "1.2",
                marginBottom: "8px",
                fontFamily: "Montserrat, sans-serif",
              }}
            >
              wine lover to wine country. It is a platform designed to
            </p>
            <p
              className="about-us-text"
              style={{
                color: "#800020",
                lineHeight: "1.2",
                marginBottom: "8px",
                fontFamily: "Montserrat, sans-serif",
              }}
            >
              elevate the experience of the wine country goer by
            </p>
            <p
              className="about-us-text"
              style={{
                color: "#800020",
                lineHeight: "1.2",
                marginBottom: "8px",
                fontFamily: "Montserrat, sans-serif",
              }}
            >
              facilitating winery visits and enabling recommendations
            </p>
            <p
              className="about-us-text"
              style={{
                color: "#800020",
                lineHeight: "1.2",
                marginBottom: "8px",
                fontFamily: "Montserrat, sans-serif",
              }}
            >
              from friends and family. It is a digital space to curate
            </p>
            <p
              className="about-us-text"
              style={{
                color: "#800020",
                lineHeight: "1.2",
                marginBottom: "8px",
                fontFamily: "Montserrat, sans-serif",
              }}
            >
              an itinerary that ensures you get to sit back and relax
            </p>
            <p
              className="about-us-text"
              style={{
                color: "#800020",
                lineHeight: "1.2",
                marginBottom: "8px",
                fontFamily: "Montserrat, sans-serif",
              }}
            >
              when swirling and sipping in the vines.
            </p>
            <p
              className="about-us-text"
              style={{
                color: "#800020",
                lineHeight: "1.2",
                marginBottom: "8px",
                fontFamily: "Montserrat, sans-serif",
              }}
            >
              The idea of TimeSavor came to fruition on a warm
            </p>
            <p
              className="about-us-text"
              style={{
                color: "#800020",
                lineHeight: "1.2",
                marginBottom: "8px",
                fontFamily: "Montserrat, sans-serif",
              }}
            >
              summer day in Napa Valley in 2022, and ever since,
            </p>
            <p
              className="about-us-text"
              style={{
                color: "#800020",
                lineHeight: "1.2",
                marginBottom: "8px",
                fontFamily: "Montserrat, sans-serif",
              }}
            >
              our objective has been:
            </p>
            <p
              className="about-us-text"
              style={{
                color: "#800020",
                lineHeight: "1.2",
                marginBottom: "8px",
                fontFamily: "Montserrat, sans-serif",
              }}
            >
              to satisfy the wine enthusiast’s travel dreams and
            </p>
            <p
              className="about-us-text"
              style={{
                color: "#800020",
                lineHeight: "1.2",
                marginBottom: "8px",
                fontFamily: "Montserrat, sans-serif",
              }}
            >
              make the joys of wine country accessible to all.
            </p>
          </div>
        </div>
      </section>
      <section
      className={`relative py-20 bg-gray-100 section1 hidden mobile ${
        pageLoaded ? "animate-pageTransition" : ""
      }`}
      style={{
        animation: pageLoaded ? "pageTransition 0.5s ease-out" : "none",
        opacity: pageLoaded ? 1 : 0,
        transform: pageLoaded ? "translateY(0)" : "translateY(20px)",
        transition: "opacity 0.5s ease-out, transform 0.5s ease-out",
        width: "100vw",
        margin: 0,
        padding: 0,
        textAlign: "center",
        overflow: "hidden",
      }}>
        <img className="mt-16" src="https://res.cloudinary.com/dtpevqqw5/image/upload/v1724850172/ayypdqxxshu9r3sd4bfs.png" alt="background"/>
        <div
          
          style={{
            padding: "6px",
            opacity: "0.85",
            backgroundColor: "rgba(195, 195, 195, 0.686)",
            backdropFilter: "blur(1px)",
            color: "black",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>

          <h1
            className="heading"
            style={{
              color: "#800020",
              fontFamily: "Baskervville, serif",
              marginRight : "0",
              fontSize : "3rem",
            }}
          >
            About Us
          </h1>
          
          <div
          style={{
            color: "#800020",
            lineHeight: "1.4",
            marginBottom: "8px",
            marginTop: "10px",
            fontFamily: "Montserrat, sans-serif",
            fontSize: "14px",
            fontWeight: "400",
            maxWidth: "470px",
            padding: "0 40px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          
          >
            <p style={{maxWidth: "290px"}}
            >We believe time is like wine. <br/> It is meant to be shared and savored.</p>
            <br/>
            <p style={{maxWidth: "320px"}}
            >Our mission: simplify the planning of your trip to wine country so you can savor every minute of it.</p>
            <br/>
            <p 
            >TimeSavor is a tech company that connects the  wine lover to wine country. It is a platform designed to elevate the experience of the wine country goer by facilitating winery visits and enabling recommendations from friends and family. It is a digital space to curate an itinerary that ensures you get to sit back and relax when swirling and sipping in the vines.</p>
            <br/>
            <p style={{maxWidth: "440px"}}
            >The idea of TimeSavor came to fruition on a warm summer day in Napa Valley in 2022, and ever since, our objective has been:
            to satisfy the wine enthusiast's travel dreams and make the joys of wine country accessible to all.</p>
            </div>
            <br/>



        </div>


      </section>
      <Footer />
    </>
  );
}