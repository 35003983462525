import React from "react";
import { Link, useHistory } from "react-router-dom";
import IndexDropdown from "../Dropdowns/IndexDropdown"; // Adjust the path as needed
import "./Navbar.css"; // Import the custom CSS

export default function Navbar(props) {
  const history = useHistory();

  return (
    <>
      <nav className="navbar-custom">
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl p-4">
          <Link to="/" className="flex items-center">
            <img
              src="https://res.cloudinary.com/dtpevqqw5/image/upload/v1722860927/uphfxx840sgsr0oxpl4e.png"
              alt="TimeSavor Logo"
              className="h-10 w-auto"
            />
          </Link>

          <div className="flex items-center space-x-6 rtl:space-x-reverse">
            <IndexDropdown onLogin={props.onLogin} />
          </div>
        </div>
      </nav>
    </>
  );
}
