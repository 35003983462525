import React from 'react';
import './CustomAlert.css';

const CustomAlert = ({ message, onClose }) => {
  return (
    <div className="customAlert-overlay">
      <div className="customAlert-container">
        <p>{message}</p>
        <button className="customAlert-close-btn" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default CustomAlert;
