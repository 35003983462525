import React, { useEffect, useState } from 'react';
import Navbar from "components/Navbars/IndexNavbar.js";
import './Profile.css';

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import Spinner from "components/Spinner/Spinner";
import { MdEmail, MdRealEstateAgent, MdJoinFull } from 'react-icons/md';
import { GiWineBottle } from "react-icons/gi";
import { IoWineSharp } from "react-icons/io5";
import Alert from '../components/Alert/Alert'; // Import the Alert component

export default function Profile() {
  const [user, setUser] = useState(null);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    const fetchProfile = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        console.error('No user found or the user must be authenticated');
        return;
      }

      try {
        const response = await fetch('https://timesavor-server.onrender.com/api/buyer/profile', {
          headers: {
            'x-access-token': token,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch profile data');
        }

        const data = await response.json();
        setUser(data.user);
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };

    fetchProfile();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAlert(true);  // Show the custom alert after 5 seconds
    }, 15000); // Show alert after 5 seconds

    // Clear the timer if the component is unmounted
    return () => clearTimeout(timer);
  }, []);

  const handleAlertClose = () => {
    setShowAlert(false);
  };

  const handleAlertButtonClick = () => {
    setShowAlert(false);
  };

  if (!user) {
    return <Spinner />;
  }

  return (
    <>
      <IndexNavbar />
      <main className="profile-page">
        <section className="profile-header">
          <div className="overlay">
            <div className="profile-pic-container">
              <img alt="User Profile" src={user.Picture} className="profile-pic" />
            </div>
          </div>
        </section>

        <section className="profile-details">
          <div className="container">
            <div className="user-info">
              <h3 className="user-name">{user.firstname}</h3>
              <button className="username-button">@{user.username}</button>
            </div>
            <div className="stats-enhanced">
  <div className="stat-enhanced">
    <div className="stat-number">{user.followers}</div>
    <div className="stat-label">Followers</div>
  </div>
  <div className="stat-enhanced">
    <div className="stat-number">{user.following}</div>
    <div className="stat-label">Following</div>
  </div>
  <div className="stat-enhanced">
    <div className="stat-number">{user.totalVisitedWineries}</div>
    <div className="stat-label">Visited Wineries</div>
  </div>
</div>


<div className="info-box">
  <div className="info-item">
    <MdEmail className="icon" />
    <span>{user.email}</span>
  </div>
  <div className="info-item">
    <IoWineSharp className="icon" />
    <span>{user.questionnaire.winePreference} Wine Lover</span>
  </div>
  <div className="info-item">
    <GiWineBottle className="icon" />
    <span>{user.questionnaire.wineLoverLevel}</span>
  </div>
  <div className="info-item">
    <MdJoinFull className="icon" />
    <span>Wine Clubs Joined: {user.questionnaire.wineClubsJoined}</span>
  </div>
  <div className="info-item">
    <MdRealEstateAgent className="icon" />
    <span>Been to Wine Country: {user.questionnaire.beenToWineCountry}</span>
  </div>
</div>

          </div>
        </section>
      </main>
      <Footer />
      {showAlert && (
        <Alert
          type="info"
          title="Get Our Mobile App!"
          message="Please use our mobile app 'Timesavor' for further use."
          buttonText="Got it!"
          onButtonClick={handleAlertButtonClick}
          onClose={handleAlertClose}
        />
      )}
    </>
  );
}
