import React, { useEffect } from 'react';
import '../assets/styles/TermsAndService.css';
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import htmlContent from './terms.html';

const TermsAndService = () => {
  useEffect(() => {
    const sections = document.querySelectorAll('.terms-section');
    sections.forEach((section, index) => {
      setTimeout(() => {
        section.classList.add('animate');
      }, index * 200); // Adjust delay as needed
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    <IndexNavbar/>
    <div style={{marginTop:"10px",padding:"20px"}} dangerouslySetInnerHTML={{ __html: htmlContent }} />
    <Footer/>
    </>
  );
};

export default TermsAndService;
