import React, { useRef } from "react";
import { Link } from "react-router-dom";
import css from "../assets/styles/form.css";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import ContactForm from "components/emailForm/ContactForm";
import IndexDropdown from "components/Dropdowns/IndexDropdown";

export default function Index() {
  const aboutUsRef = useRef(null);
  const howItWorksRef = useRef(null);
  const ourServicesRef = useRef(null);
  const contactSectionRef = useRef(null);

  const scrollToSection = (ref) => {
    const offset = -100; // Adjust this value to fine-tune the scroll position
    const elementPosition = ref.current.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset + offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  return (
    <>
      <style>
        {`
          @keyframes fadeInUp {
            from {
              opacity: 0;
              transform: translateY(20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }

          h2 {
            font-size: 3.0rem;
            font-weight: 400;
            color: white;
            margin-bottom: 28rem;
            opacity: 0;
            font-family: 'Montserrat', sans-serif;
            transform: translateY(20px);
            animation: fadeInUp 1s ease-out 0.3s forwards;
          }

          @media (max-width: 1024px) {
            h2 {
              font-size: 2.5rem;
              margin-bottom: 10rem;
            }
          }

          @media (max-width: 768px) {
            h2 {
              font-size: 2rem;
              margin-bottom: 5rem;
            }
          }

          @media (max-width: 480px) {
            h2 {
              font-size: 2.2rem;
              margin-bottom: 19rem;
            }
          }
        `}
      </style>
      <IndexNavbar
        scrollToAboutUs={() => scrollToSection(aboutUsRef)}
        scrollToHowItWorks={() => scrollToSection(howItWorksRef)}
        scrollToOurServices={() => scrollToSection(ourServicesRef)}
        scrollToContact={() => scrollToSection(contactSectionRef)}
      />
    
      <section
        style={{
          width: '100vw',
          height: '100vh',
          maxWidth: '100%',
          margin: 0,
          padding: 0,
          position: 'relative',
          overflow: 'hidden'
        }}
        className="header flex"
      >
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%'
        }}>
          <video
            autoPlay
            loop
            muted
            playsInline  // Added playsInline for iPhone support
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
            onError={(e) => console.error("Video error:", e)}
          >
            <source src="https://res.cloudinary.com/dtpevqqw5/video/upload/v1722860642/Untitled_video_-_Made_with_Clipchamp_nxjxkg.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          {/* Dark overlay */}
          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.16)'
          }}></div>
        </div>
        {/* Content */}
        <div style={{
          position: 'relative',
          zIndex: 10,
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          paddingLeft: '5%',
          opacity: 0,
          transform: 'translateY(20px)',
          animation: 'fadeInUp 1s ease-out forwards'
        }}>
          <div style={{
            maxWidth: '1500px',
            padding: '0 20px'
          }}>
            <h2>Your journey through the world of wine starts here.</h2>
          </div>
        </div>
      </section>

      <Footer
        scrollToAboutUs={() => scrollToSection(aboutUsRef)}
        scrollToContact={() => scrollToSection(contactSectionRef)}
      />
    </>
  );
}
