import React, { useState, useEffect } from 'react';
import './Multiselect.css';

const MultiSelect = ({ options, value, onChange, label, isOpen, onDropdownToggle }) => {
  const [selectedOptions, setSelectedOptions] = useState(value || []);

  useEffect(() => {
    setSelectedOptions(value || []);
  }, [value]);

  const handleCheckboxChange = (option) => {
    let newSelectedOptions = [];
    if (selectedOptions.includes(option)) {
      newSelectedOptions = selectedOptions.filter(item => item !== option);
    } else {
      newSelectedOptions = [...selectedOptions, option];
    }
    setSelectedOptions(newSelectedOptions);
    onChange(newSelectedOptions);
  };

  const toggleDropdown = () => {
    onDropdownToggle(); // Notify parent component to control open/close state
  };

  return (
    <div className="multi-select">
      <label className="form-label">{label}</label>
      <div
        className={`multi-select-dropdown ${isOpen ? 'open' : ''}`}
        onClick={toggleDropdown}
      >
        {selectedOptions.length > 0 ? selectedOptions.join(', ') : 'Select options'}
      </div>
      {isOpen && (
        <div className="multi-select-options">
          {options.map((option) => (
            <div key={option} className="multi-select-option">
              <input
                type="checkbox"
                id={option}
                name={option}
                value={option}
                checked={selectedOptions.includes(option)}
                onChange={() => handleCheckboxChange(option)}
              />
              <label htmlFor={option}>{option}</label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiSelect;
