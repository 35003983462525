import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import IndexNavbar from 'components/Navbars/IndexNavbar.js';
import Footer from 'components/Footers/Footer.js';
import './ContactForm.css';

const validationSchema = yup.object().shape({
  firstname: yup.string().required('First name is required').min(2, 'First name must be at least 2 characters'),
  lastname: yup.string().required('Last name is required').min(2, 'Last name must be at least 2 characters'),
  email: yup.string().email('Invalid email').required('Email is required'),
  message: yup.string().required('Message is required').min(10, 'Message must be at least 10 characters'),
});

const ContactForm = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await fetch('https://timesavor-server.onrender.com/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Failed to send message. Please try again.');
      }

      setSuccess('Message sent successfully!');
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const montserratStyle = {
    fontFamily: "'Montserrat', sans-serif",
  };

  return (
    <>
      <div className="full-page-background" style={montserratStyle}>
        <IndexNavbar />
        <div className="container mx-auto" style={{marginTop : "-100px"}}>
          <div className="text-center mb-12">
            <h1 style={{ fontFamily: "Baskervville, serif" }} className="custom-contact-heading-unique">
              Contact Us
            </h1>
          </div>
          <div className="flex flex-col items-center py-12 bg-gray-50">
            <div className="container-cont">
              {success ? (
                <div className="success-container">
               <span className="block sm:inline font-bold success-text">Message sent successfully!</span>
                  <span className="block sm:inline font-bold success-text"> Cheers!</span>
                </div>
              ) : (
                <>
                  <div className="picture-container-cont">
                    <div className="picture-cont">
                      <p>Have a question or need recommendations?</p>
                      <p>We’re here for you!</p>
                    </div>
                  </div>
                  <div className="contact-form-container-cont">
  <form onSubmit={handleSubmit(onSubmit)} className="contact-form-cont">
    <div className="flex flex-wrap">
      <div className="w-full px-3 md:w-1/2">
        <label style={{ color: "#800020" }} className="block text-lg mb-2" htmlFor="firstname">
          First Name*
        </label>
        <input
          type="text"
          name="firstname"
          {...register('firstname')}
          className="email-input-cont"
        />
        {errors.firstname && (
          <div className="text-red-500 text-sm">{errors.firstname.message}</div>
        )}
      </div>
      <div className="w-full px-3 md:w-1/2">
        <label style={{ color: "#800020" }} className="block text-lg mb-2" htmlFor="lastname">
          Last Name*
        </label>
        <input
          type="text"
          name="lastname"
          {...register('lastname')}
          className="email-input-cont"
        />
        {errors.lastname && (
          <div className="text-red-500 text-sm">{errors.lastname.message}</div>
        )}
      </div>
      <div className="w-full px-3 md:w-1/2">
        <label style={{ color: "#800020" }} className="block text-lg mb-2 " htmlFor="email">
          Email*
        </label>
        <input
          type="email"
          name="email"
          {...register('email')}
          className="email-input-cont"
        />
        {errors.email && (
          <div className="text-red-500 text-sm">{errors.email.message}</div>
        )}
      </div>
    </div>

    <div className="mb-6 w-full px-3">
      <label style={{ color: "#800020" }} className="block text-lg mb-2" htmlFor="message">
        Message*
      </label>
      <textarea
        name="message"
        cols="30"
        rows="5"
        {...register('message')}
        className="message-cont"
      ></textarea>
      {errors.message && (
        <div className="text-red-500 text-sm">{errors.message.message}</div>
      )}
    </div>
    {error && (
      <div className="text-red-500 text-sm text-center mb-4">{error}</div>
    )}
    <button
      type="submit"
      className="submit-cont py-2 px-4 rounded flex items-center justify-center"
      disabled={loading}
    >
      {loading ? (
        <>
          <span className="dot-trail"></span>
          <span className="ml-2">Sending...</span>
        </>
      ) : (
        'Send Message'
      )}
    </button>
  </form>
</div>

                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactForm;