import React, { useState } from 'react';
import axios from 'axios';
import './ForgotPasswordPage.css';
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import CustomAlert from '../../components/Alert/CustomAlert'; // Import the custom alert

const ForgotPasswordPage = () => {
  const baseUrl = 'https://timesavor-server.onrender.com';

  const [userType, setUserType] = useState('user');
  const [email, setEmail] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [loading, setLoading] = useState(false); // Add loading state

  const handleToggleSwitch = (type) => {
    setUserType(type);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (emailError) {
      validateEmail(e.target.value);
    }
  };

  const handleResetPassword = async () => {
    if (validateEmail(email)) {
      setLoading(true); // Set loading to true
      try {
        const apiUrl =
          userType === 'user'
            ? `${baseUrl}/api/buyer/forgot-password`
            : `${baseUrl}/api/business/forgotPassword`;

        await axios.post(apiUrl, { email });
        setAlertMessage('Password reset instructions have been sent to your email address.');
        setShowAlert(true);
      } catch (error) {
        console.error('Error sending password reset email:', error);
        setAlertMessage('An error occurred. Please try again later.');
        setShowAlert(true);
      } finally {
        setLoading(false); // Set loading to false after the request is completed
      }
    } else {
      setAlertMessage('Please enter a valid email address.');
      setShowAlert(true);
    }
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  // Email validation
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(email);
    setEmailError(isValid ? '' : 'Please enter a valid email address.');
    return isValid;
  };

  return (
    <>
      <div className="forgotPasswordPage-container">
        <IndexNavbar />
        <div className="forgotPasswordPage-card">
          <h1 className="forgotPasswordPage-title">Forgot Password</h1>
          <div className="forgotPasswordPage-toggle-switch">
            <label
              className={`forgotPasswordPage-toggle-label ${userType === 'user' ? 'forgotPasswordPage-toggle-label--active' : ''}`}
              onClick={() => handleToggleSwitch('user')}
            >
              User
            </label>
            <label
              className={`forgotPasswordPage-toggle-label ${userType === 'winery' ? 'forgotPasswordPage-toggle-label--active' : ''}`}
              onClick={() => handleToggleSwitch('winery')}
            >
              Winery
            </label>
          </div>
          <input
            type="email"
            className="forgotPasswordPage-input-field"
            placeholder="Enter your email address"
            value={email}
            onChange={handleEmailChange}
          />
          {emailError && <div className="forgotPasswordPage-error">{emailError}</div>}
          <button className="forgotPasswordPage-btn" onClick={handleResetPassword} disabled={loading}>
            {loading ? <span className="loader"></span> : `Reset Password as ${userType.charAt(0).toUpperCase() + userType.slice(1)}`}
          </button>
        </div>
      </div>
      <Footer />
      {showAlert && <CustomAlert message={alertMessage} onClose={handleCloseAlert} />}
    </>
  );
};

export default ForgotPasswordPage;
