// components/Spinner.js
import React from "react";
import "../../assets/styles/spinner.css";
import spinner from "../../assets/spinnerlogos.png";

const Spinner = () => {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white', /* Optional: to cover the entire page */
        zIndex: 9999 /* To make sure it stays on top */
      }}
    >
      <img src={spinner} alt="Loading..." className="spinner" />
    </div>
  );
};

export default Spinner;
