import React, { useState, useEffect } from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import hows from "../views/howsitwoks.png";
import save from "../assets/img/Save.png"
import explore from "../assets/img/Explore.png"
import plan from "../assets/img/Plan.png"
import comeTogether from "../assets/img/comeTogrther.png"

export default function AboutUs() {
  
  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => setPageLoaded(true), 100);
  }, []);

  const pageTransition = `
    @keyframes pageTransition {
      from { opacity: 0; transform: translateY(20px); }
      to { opacity: 1; transform: translateY(0); }
    }
  `;

  const responsiveStyles = `
    @media (max-width: 768px) {
      .desktop {
        display: none;
      }
      .mobile{
        display: block;
      }
      .heading {
      font-size: 3em; 
        
        text-align: center;
        top: 10%;
        left: 50%;
        width: 100%; /* Increased width for mobile screens */
        transform: translateX(-50%);
        position: absolute;
        white-space: nowrap; /* Prevents wrapping */
      }
      .responsive-box {
        width: 189%;
        height: auto;
        bottom: 10%;
        padding-bottom:120px;
        transform: translateX(-50%);
        left: 50%;
        position: absolute;
      }
    }

    @media (min-width: 769px) {
      
      .heading {
        font-size: 4.8rem;
        text-align: left;
        top: 9%;
        left: 5%;
        position: absolute;
      }
      .responsive-box {
        width: 100%;
        height: auto;
        top:10%;
        transform: translateX(-50%);
        left: 50%;
        position: absolute;
      }
    }
  `;

  return (
    <>
      <style>{pageTransition}</style>
      <style>{responsiveStyles}</style>
      <IndexNavbar />
      <section
        className={`relative py-20 bg-gray-100 desktop ${
          pageLoaded ? "animate-pageTransition" : ""
        }`}
        style={{
          animation: pageLoaded ? "pageTransition 0.5s ease-out" : "none",
          opacity: pageLoaded ? 1 : 0,
          transform: pageLoaded ? "translateY(0)" : "translateY(20px)",
          transition: "opacity 0.5s ease-out, transform 0.5s ease-out",
          height: "100vh",
          width: "100vw",
          margin: 0,
          padding: 0,
          textAlign: "center",
        }}
      >
        <div
          className="absolute inset-0 overflow-hidden"
          style={{ height: "100%", width: "100%", overflow: "hidden" }}
        >
          <img
            src="https://res.cloudinary.com/dtpevqqw5/image/upload/v1722583815/uyotziqgewyw3niqnr9z.jpg"
            alt="background"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
          <div
            className="heading"
            style={{ color: "#800020", fontFamily: "Baskervville, serif",fontWeight:"500" }}
          >
            How It Works
          </div>
          <div
            className="absolute responsive-box"
            style={{
              position: "absolute",
              top: "60%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div style={{width:"40%", margin:"0 auto" , position:"relative",marginInline : "auto"}}>
              <img  style={{position:"relative" , mixBlendMode : "multiply" , zIndex:"1"}} src={explore} />
              <div className="flex"
                style={{
                  position:"relative",
                  left:"-50%",
                  margin:"-25% 0",
                  paddingTop : "3%"
                }}>
              <img  src={comeTogether}/>
              <img  src={save}/>
              </div>
              <img  style={{position:"relative" , mixBlendMode : "multiply" , zIndex:"1"}} src={plan}/>
            </div>
          </div>
        </div>
      </section>
      <section
        className={`relative py-20 bg-gray-100 hidden mobile ${
          pageLoaded ? "animate-pageTransition" : ""
        }`}
        style={{
          animation: pageLoaded ? "pageTransition 0.5s ease-out" : "none",
          opacity: pageLoaded ? 1 : 0,
          transform: pageLoaded ? "translateY(0)" : "translateY(20px)",
          transition: "opacity 0.5s ease-out, transform 0.5s ease-out",
          
          width: "100vw",
          margin: 0,
          padding: 0,
          textAlign: "center",
        }}
      >
        <img
            className="mt-16"
            src="https://res.cloudinary.com/dtpevqqw5/image/upload/v1722583815/uyotziqgewyw3niqnr9z.jpg"
            alt="background"
            
          />
        
         <div
            className="py-6 overflow-hidden"
            style={{ color: "#800020", fontFamily: "Baskervville, serif",fontWeight:"500" , fontSize:"3rem" }}
          >
            How It Works
          
          <div style={{width:"70%", margin:"0 auto" , position:"relative",}}>
            {/* explore */}
            <img style={{position:"relative" , zIndex:"1" , mixBlendMode : "multiply"}} src={explore} />
            <div className="flex"
              style={{
                position:"relative",
                left:"-50%",
                margin:"-25% 0",
                paddingTop : "3%"
              }}>
            {/* come together */}
            <img src={comeTogether}/>
                {/* save */}
            <img  src={save}/>
            </div>
            {/* plan */}
            <img style={{position:"relative" , zIndex:"1" , mixBlendMode : "multiply"}} src={plan}/>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
