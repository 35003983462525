// components/Alert.js
import React from 'react';
import { useHistory } from 'react-router-dom';
import './Alert.css'; // Import the CSS file for custom styles
import googlePlayIcon from '../../assets/img/google.png';
import appStoreIcon from '../../assets/img/appstore.png';

const Alert = ({ type, title, message, buttonText, onButtonClick, onClose }) => {
  const history = useHistory();

  const handleButtonClick = () => {
    if (onButtonClick) {
      onButtonClick();
    } else {
      history.push('/login');
    }
  };

  return (
    <div className={`alert-overlay ${type}`}>
      <div className={`alert ${type}`}>
        <h2 className="text-xl font-bold mb-4">{title}</h2>
        <p className="mb-4">{message}</p>
        <div className="store-buttons">
           
          <a href="https://play.google.com/store" target="_blank" rel="noopener noreferrer">
            <img src={googlePlayIcon} alt="Google Play" className="store-icona" />
          </a>

          <a href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer">
            <img src={appStoreIcon} alt="App Store" className="store-iconb" />
          </a>
        </div>
        <button
          onClick={handleButtonClick}
          className="alert-button"
        >
          {buttonText || 'Got it!'}
        </button>
        <button
          onClick={onClose}
          className="alert-close"
        >
          &times;
        </button>
      </div>
    </div>
  );
};

export default Alert;
