import React, { useState, useRef, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import user from "./user.png";
// import { Box } from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  Box,
  IconButton,
  TextField,
  styled
} from '@mui/material';
import { FaDatabase, FaUser, FaSave , FaTimes } from 'react-icons/fa';

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#800020',
  color: '#ffffff',
  '&:hover': {
    backgroundColor: '#a02040',
  },
  '&:disabled': {
    backgroundColor: theme.palette.action.disabledBackground,
    color: theme.palette.action.disabled,
  },
}));


export default function IndexDropdown({ onLogin }) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const history = useHistory();
  const token = localStorage.getItem('authToken');
  const [hoveredItem, setHoveredItem] = useState(null);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);
  const closeTimeout = useRef(null);
  const [deletes,setDelete] = useState(false);
  const [step, setStep] = useState(1);
  const [selectedReason, setSelectedReason] = useState('');
  const [otherReason, setOtherReason] = useState('');


  const handleClose = () => {
    setDelete(false);
    setStep(1);
    setSelectedReason('');
    setOtherReason('');
  };

  const handleContinue = () => {
    setStep(2);
  };
  const handleReasonChange = (event) => {
    setSelectedReason(event.target.value);
    if (event.target.value !== 'Other') {
      setOtherReason('');
    }
  };

  const handleOtherReasonChange = (event) => {
    setOtherReason(event.target.value);
  };

  const handleSubmitDelete = () => {
    const finalReason = selectedReason === 'Other' ? otherReason : selectedReason;
    if (finalReason) {
console.log(finalReason)
handleSubmitDeletes(finalReason)
      handleClose();
    }
  };

  const reasons = [
    "I have another TimeSavor Account",
    "I no longer wish to save wineries and tasting rooms",
    "I no longer visit wine country",
    "I wasn't pleased with experience",
    "I have Privacy Concerns",
    "Other"
  ];

  useEffect(() => {
    function handleResize() {
      setIsDesktop(window.innerWidth >= 768);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        if (closeTimeout.current) {
          clearTimeout(closeTimeout.current);
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      if (closeTimeout.current) {
        clearTimeout(closeTimeout.current);
      }
    };
  }, [dropdownRef]);

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    history.push('/LoginPage');
  };

  const handleDelete = async () =>{
     setDelete(true);
  }

  const handleSubmitDeletes = async (value) => {
    const token = localStorage.getItem('authToken');
    
    if (!token) {
      Swal.fire({
        icon: 'warning',
        text: 'Token is Expired, Please login again and then try',
        title: 'Oops...',
      });
      return; // Exit if there's no token
    }
  
    try {
      const response = await axios.delete(
        'https://timesavor-server.onrender.com/api/buyer/deleteAccount',
        {
          headers: {
            'x-access-token': token, // Set the token in headers
          },
          data: { deletionReasons: [value] }, // Provide body in a DELETE request
        }
      );
      
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          text: 'Deleting Account',
          title: 'Successfully Account deleted',
        });
        localStorage.removeItem('authToken');
        history.push('/');
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        text: error.response?.data?.message || 'Failed in Deleting account',
        title: 'Failed',
      });
    }
  };
  
  const toggleDropdown = () => {
    if (!isDesktop) {
      setIsOpen(!isOpen);
    }
  };

  const handleMouseEnter = () => {
    if (isDesktop) {
      if (closeTimeout.current) {
        clearTimeout(closeTimeout.current);
      }
      setIsOpen(true);
    }
  };

  const handleMouseLeave = () => {
    if (isDesktop) {
      closeTimeout.current = setTimeout(() => {
        setIsOpen(false);
      }, 3000); // 3-second delay
    }
  };

  return (
    <div
      className="relative inline-block text-left"
      ref={dropdownRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
     <button
    type="button"
    onClick={toggleDropdown}
    className="text-white bg-blue-600 hover:bg-blue-700 font-medium rounded-lg text-sm px-3 py-2.5 text-center inline-flex items-center no-border"
>
    <i className="fas fa-bars text-4xl mr-2" style={{ color: '#800020' }}></i>
    {/* <img width="40px" src={user} alt="User Icon" className="w-1 h-1" /> */}
</button>

      {isOpen && (
        <div
          style={{ width: "200px", marginTop: "15px", border: "2px solid #800020" }}
          className="absolute right-0 z-10 mt-2 w-120 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <div className="py-1" style={{ marginTop: "13px" }}>
            {token ? (
              <Box>
                <MenuItem
                to="/winery"
                as={Link}
                // onClick={handleProfile}
                onMouseEnter={() => setHoveredItem('profile')}
                onMouseLeave={() => setHoveredItem(null)}
                hovered={hoveredItem === 'profile'}
              >
                Profile
              </MenuItem>
              <MenuItem
              as={Link}
                onClick={handleLogout}
                onMouseEnter={() => setHoveredItem('logout')}
                onMouseLeave={() => setHoveredItem(null)}
                hovered={hoveredItem === 'logout'}
              >
                Logout
              </MenuItem>
               <MenuItem
               as={Link}
                 onClick={handleDelete}
                 onMouseEnter={() => setHoveredItem('delete')}
                 onMouseLeave={() => setHoveredItem(null)}
                 hovered={hoveredItem === 'delete'}
               >
                 Delete Account
               </MenuItem>
               </Box>
            ) : (
              <Box>
              <MenuItem
                as={Link}
                to="/LoginPage"
                onClick={() => setIsOpen(false)}
                onMouseEnter={() => setHoveredItem('login')}
                onMouseLeave={() => setHoveredItem(null)}
                hovered={hoveredItem === 'login'}
              >
                Login
              </MenuItem>
              <MenuItem
              as={Link}
              to="/Register"
              onClick={() => setIsOpen(false)}
              onMouseEnter={() => setHoveredItem('signup')}
              onMouseLeave={() => setHoveredItem(null)}
              hovered={hoveredItem === 'signup'}
            >
              Sign Up
            </MenuItem>
            </Box>
            )}
            

            <div style={{ borderTop: '8px solid #800020' }}></div>

            <MenuItem
              as={Link}
              to="/About"
              onClick={() => setIsOpen(false)}
              onMouseEnter={() => setHoveredItem('about')}
              onMouseLeave={() => setHoveredItem(null)}
              hovered={hoveredItem === 'about'}
            >
              About Us
            </MenuItem>

            <MenuItem
              as={Link}
              to="/Howitworks"
              onClick={() => setIsOpen(false)}
              onMouseEnter={() => setHoveredItem('howitworks')}
              onMouseLeave={() => setHoveredItem(null)}
              hovered={hoveredItem === 'howitworks'}
            >
              How It Works
            </MenuItem>

            <MenuItem
              as={Link}
              to="/Contact"
              onClick={() => setIsOpen(false)}
              onMouseEnter={() => setHoveredItem('contact')}
              onMouseLeave={() => setHoveredItem(null)}
              hovered={hoveredItem === 'contact'}
            >
              Contact us
            </MenuItem>
          </div>
        </div>
      )}

<Dialog open={deletes} onClose={handleClose}>
      {/* <DialogTitle>Delete Account</DialogTitle> */}
      <DialogContent>
      <DialogTitle>
        {step === 1 ?
      <Typography variant="h6" component="div" sx={{fontSize : "25px" , fontWeight:"semibold",color : "#800020"}}>
          Are you sure you want to delete account?
        </Typography> : 
        <Typography variant="h6" component="div" sx={{fontSize : "25px" , fontWeight:"semibold",color : "#800020"}}>
        Why did you choose to close your account
      </Typography>}
      <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <FaTimes />
        </IconButton>
        
      </DialogTitle>
      {step === 1 ? (
          <Box sx={{ mt: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
              <FaDatabase sx={{ mr: 1 }} />
              <Typography sx={{fontSize : "15px" , marginLeft : "5px" , color : "GrayText"}}>All Data and history will no longer be accessible
    </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
              <FaUser sx={{ mr: 1 }} />
              <Typography sx={{fontSize : "15px" , marginLeft : "5px",color : "GrayText"}}>Your Profile will be Permanently Deleted
             </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
              <FaSave sx={{ mr: 1 }} />
              <Typography sx={{fontSize : "15px" , marginLeft : "5px",color : "GrayText"}}> All Planned Itineraries and Saved Vineyard will be deleted</Typography>
            </Box>
          </Box>

        ) : (
          <Box>
            {reasons.map((reason) => (
              <FormControlLabel
                key={reason}
                control={
                  <Checkbox
                    checked={selectedReason === reason}
                    onChange={handleReasonChange}
                    value={reason}
                  />
                }
                label={reason}
                sx={{ display: 'block', mt: 1 }}
              />
            ))}
            {selectedReason === 'Other' && (
             <TextField
             fullWidth
             placeholder="Please specify"
             value={otherReason}
             onChange={handleOtherReasonChange}
           />
           
            )}
          </Box>
        )}
      </DialogContent>
      <DialogActions>

      {step === 1 ? (
          <CustomButton onClick={handleContinue}>
            Continue
          </CustomButton>
        ) : (
          <CustomButton
            onClick={handleSubmitDelete}
            disabled={selectedReason === '' || (selectedReason === 'Other' && otherReason === '')}
          >
            Delete Account
          </CustomButton>
        )}
        
      </DialogActions>
    </Dialog>
    </div>
    
  );
}

function MenuItem({ children, onClick, as: Component = 'button', hovered, ...props }) {
  return (
    <Component
      onClick={onClick}
      style={{
        backgroundColor: hovered ? '#800020' : 'white',
        color: hovered ? 'white' : '#800020',
        transition: 'background-color 0.2s, color 0.2s',
        textAlign: 'left',
        width: '100%',
        padding: '8px 16px',
        display: 'block',
        fontSize: '14px',
        fontWeight: 'bold',
      }}
      {...props}
    >
      {children}
    </Component>
  );
}
